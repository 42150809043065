/** Eproms Shared services portal CSS
 * Uses Bootstrap framework
 *
 * = Font sizing =
 * Baseline font sizes: body: 18px, headlines: 24px, subheads: 22px, form fields: 18px, buttons: 18px, smaller-text: 14px
 * > 992 font sizes: body: 24px, headlines 32px, subheads: 29px, form fields: 18px, buttons: 18px, smaller-text: 14px
**/
@import "logo.less";
@TrueNTHLogoThumbnailWidth: 120px;
@TrueNTHLogoThumbnailHeight: 38px;
@MovemberLogoThumbnailWidth: 120px;
@MovemberLogoThumbnailHeight: 42px;
@font-face {
    font-family: "symbols";
    src: url("/static/fonts/symbols.eot");
    src: url("/static/fonts/symbols.eot?#iefix") format("eot"), url("/static/fonts/symbols.svg#symbols") format("svg"), url("/static/fonts/symbols.woff2") format("woff2"), url("/static/fonts/symbols.woff") format("woff"), url("/static/fonts/symbols.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@baseFontSize:    16px;
@baseLargerFontSize: 18px;
@baseMobileFontSize:  14px;
@jumbotranSize:   1.55em;
@mobileJumbotranSize: 1.45em;
@headlineSize:    1.60em;
@subheadSize:     1.30em;
@subSmallHeadSize: 1.1em;
@lgFontSize:      1.475em;
@lgHeadlineSize:  1.35em;
@lgsubheadSize:   1.25em;
@splashFontSize:  1.05em;
@smallText:       0.90em;
@mediumText:      0.95em;
@smallerText:     0.85em;
@xsmallText:      0.75em;
@xxsmallText:     0.70em;
@btnSize:         1em;
@smallBtnSize:    0.9em;
@orgSize:         15px;
@mobileOrgSize:   12px;
@modalHeaderSize: 1.3em;
@modalTitleSize:  1em;
@modalBodySize:   1.07em;
@modalButtonSize: 0.85em;
@modalLabelSize:  1.01em;
@modalInputSize:  0.90em;
@footerSize:      0.95em;
@footerLinkSize:  0.95em;
@linkSize:        0.9em;
@mobileSize:      14px;
@mobileSmallSize:      0.7777em;
@formSize:        16px;

@jumboInputHeight: 48px;

/**
 * = Colors =
 **/
@bodyBgColor:      #FFF;
@mainNavMobileBgColor: #353F4B;
@mainNavBgColor: linear-gradient(to right, #6B7581, #38424E, #6B7581, #353F4B);
@baseColor:       #333;
@linkColor:       #57675B;
@linkHover:       #576e76;
@btnDefaultColor: #333f4b;
@btnDefaultBorderColor: #bdb9b9;
@inputBorderColor: #bdb9b9;
@btnColor:        #7C959E;
@featureBtnColor: #7C959E;
@featureBtnHoverColor: #FFF;
@featureBtnHoverBgColor: #909698;
@featureBtnBorderColor: #333;
@headlineColor: #3F4B55;
@legendColor:    #595F57;
@modalBackgroundColor: #606a73;
@sectionTitleColor: #526960;
@titleTextColor: #5C5E60;
@sectionBorderColor: #E0E6E3;
@rowBackgroundColor: #8a8e90;
@navBarBgColor: #607D8B;
@toolTipColor: #575a4d;
@menuTextColor: #494e37;
@menuTextHoverColor: #337ab7;
@menuTitleColor: #5b5e61;
@leftPanelBgColor: #bac1ca;
@errorMessageColor: #a94442;
@placeHolderColor: #999;
@disabledOpacity: 0.6;
@disabledColor: #bfc3c3;
@footerWhiteThemeColor: #F5F5F5;
@orBackgroundColor: #888a8c;
@labelColor: #777;
@mutedColor: #777;
@muterColor: #ececec;
@inputHeight: 2.5em;
@inputFocusBgColor: #f3f4f5;
@wellColor: #F7F7F7;
@notificationBgColor: #EB1939;
@notificationLinkHoverBgColor: #CC112D;
@alertColor: hsla(1, 44%, 46%, 0.95);
@warningColor: #8a6d3b;
@HRColor: #eeeeee;
@landingInputBorderColor: #AFB8C2;
@reportContentBorderColor: #ececec;
@reportHeadRowBackgroundColor: #efefe8;
@profileItemBgColor: #e8e8e8;
@toolbarBorderColor: #f5f4f4;
@emproBtnPrimaryColor: #60676E;
@alertColor: #cc0a0a;
@hourGlassColor: #919EB3;
@hourGlassFrameColor: #919EB3;
@hourGlassSandColor: #EDD0AA;



/**
 *  Font-family
 **/
 @bodyFontFamily:  "Helvetica Neue Light", "Helvetica Neue", Arial, sans-serif;


html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: @bodyBgColor;
  font-family: @bodyFontFamily;
  color: @baseColor;
  font-size: @baseMobileFontSize;
  text-rendering: optimizeLegibility;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background: #FFF;
  opacity: 0.5;
}

/* landing page/un-authenticated page only */
.watermark-nowrapper {
  position: absolute;
  top: 8em;
  right: 0;
  z-index: 1000;
  font-weight: normal;
  text-align: center;
  width: 100%;
  max-width: 100%;
  font-size: 0.7em;
  letter-spacing: 1px;
  text-transform: capitalize;
  padding: 1em;
  background-color: transparent;
  color: #FFF;
}
.watermark-nowrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  font-weight: normal;
  text-align: center;
  width: 100%;
  max-width: 100%;
  font-size: 0.7em;
  letter-spacing: 1px;
  text-transform: capitalize;
  padding: 0.1em 0;
  background-color: transparent;
  color: #603b00;
  background-color: #eeab50;
}
@media (min-width: 641px) {
  .watermark-nowrapper {
    font-size: 0.7em;
    top: 0;
  }
}
@media (min-width: 992px) {
  .watermark-nowrapper {
    padding: 0;
    width: 100%;
    font-size: 0.85em;
  }
}
.sys-maintenance {
  margin: auto;
  font-size: 0.8em;
  color: #FFF;
  width: 100%;
  text-align: center;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 20;
  img {
    margin: 1em auto;
    width: 100px;
    height: 45px;
    display: inline-block;
  }
  .message-container {
    background: @alertColor;
    text-align: center;
    padding: 0.25em 0 0.25em;
    width: 100%;
  }
  .message {
    line-height: 26px;
    font-weight: normal;
    display: inline-block;
  }
  .title {
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0.6em;
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .sys-maintenance {
    font-size: @baseFontSize;
    img {
      margin: 0.5em 1.5em;
      width: 150px;
      height: auto;
    }
  }
}
.save-loader-wrapper {
  position: relative;
  margin-top: 4px;
  min-height: 12px;
  .success-icon {
    color: green;
  }
  .error-icon {
    color: red;
  }
  .save-info {
    position: absolute;
    opacity: 0;
    left: 0;
     -webkit-transition: opacity 0.8s ease-in;
    transition: opacity 0.8s ease-in;
    font-size: @smallText;
  }
}
@media (min-width: 768px) {
  .save-loader-wrapper {
    min-height: 8px;
  }
}
.clamped-container {
  font-size: 1em;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: 8.4em;
  line-height: 1.4em;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  h1,h2,h3,h4,h5,h6 {
    display: none;
  }
  &:empty {
    height: 0;
    -webkit-line-clamp: 0;
  }
}
#fillOrgs.org-tree {
  flex-direction: row;
}
.email-ready-message {
  padding: 8px 5px;
  span.warning {
    display: inline-block;
    margin-right: 8px;
  }
}
.form-group {
  margin-bottom: 1em;
  label {
    margin-bottom: 3px;
  }
}
.form-control,
input,
select,
label,
legend,
.form-group > label{
  font-size: @baseMobileFontSize;
}
.tnth-headline,
.tnth-subhead,
h1, h2 {
  font-size: @subheadSize;
}
h3, h4, h5 {
  font-size: @subSmallHeadSize;
}
small,
.fixed-table-container thead th,
.fixed-table-container thead th .both,
.form-group .help-block {
  font-size: 12px;
}

.fixed-table-pagination {
    font-size:@smallText;
}

button,
#createUserLink,
.btn,
.btn-tnth-primary,
a.btn,
.sm-btn {
  font-size: @baseMobileFontSize;
  max-width: 100%;
}

.btn {
  cursor: pointer;
  border-radius: 0;
  padding: 0.8em;
  letter-spacing: 2px;
  color: @btnDefaultColor;
  background-color: #FFF;
  border: 1px solid @btnDefaultBorderColor;
  text-transform: uppercase;
  text-align: center;
}
.btn:hover {
  cursor: pointer;
}
.btn:focus,
a:focus {
  outline: none;
}

.btn.disabled,
.btn-tnth-primary.disabled {
  opacity: @disabledOpacity;
}

.btn-set {
  margin-bottom: 1.5em;
  .btn {
    margin-bottom: 0.5em;
  }
}

.btn-tnth-primary {
  color: #fff;
  background-color: @btnColor;
  border-color: @btnColor;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  padding-right: 1em;
  border-radius: 0;
  letter-spacing: 2px;
  min-width: 100px;
  display: inline-block;
  text-transform: uppercase;
  &:focus {
    background-color: @btnColor;
    border-color: @btnColor;
    color: #fff;
  }
  &:hover,
  &.active,
  .open > .dropdown-toggle& {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
  .glyphicon {
    color: #FFF;
  }
}

.btn-emphasis {
  font-weight: 600;
}

a {
  color: @linkColor;
  cursor: pointer;
}
a:hover, a:active {
  color: @linkHover;
}
a.disabled {
  opacity: @disabledOpacity;
}
a.btn {
  cursor: pointer;
  border: none
}
.btn a {
  text-decoration: none;
  word-break: break-word;
  white-space: normal;
}
.btn-tnth-back {
  color: #777;
  padding: 0;
  margin: 1em 0.2em;
}
.sub {
  font-size: smaller;
}
.chips-list {
  margin-bottom: 16px;
}
.chip {
  align-items: center;
  cursor: default;
  display: inline-flex;
  line-height: 20px;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-decoration: none;
  transition-duration: .28s;
  transition-property: box-shadow,opacity;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  vertical-align: middle;
  white-space: nowrap;
  background-color: #FFF;
  border-color: #FFF;
  color: @baseColor;
  border-radius: 16px;
  margin-right: 12px;
  margin-bottom: 16px;
  position: relative;
  &::before {
    z-index: -1;
    display: none;
  }
  &.inactive:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #FFF;
    opacity: 0.4;
    z-index: 100;
    right: 0;
    left: 0;
  }
  .chip-content {
    align-items: center;
    display: inline-flex;
    height: 100%;
    max-width: 100%;
    padding: 8px;
  }
  .decorator-text {
    font-weight: 500;
    margin-left: 8px;
    font-size: @mobileSize;
    color: @mutedColor;
  }
  //button
  .chip-icon {
    font-size: 18px;
    height: 18px;
    width: 18px;
    margin-right: 12px;
    margin-left: -16px;
    color: inherit;
    background-color: transparent;
    border: 0;
  }
  .v-icon__svg {
    fill: @mutedColor;
    font-size: 18px;
    height: 18px;
    width: 18px;
  }
}
body.landing {
  min-height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  img.desktop {
    display: none;
  }
  img.mobile {
    display: block;
  }
  header {
    position: absolute;
    z-index: 4;
    left: 0;
    top: 2em;
    width: 100%;
    img {
      margin: auto;
      width: 130px;
      display: block;
    }
  }
  #loadingIndicator {
    z-index: 10;
  }
  .message-section {
    position: absolute;
    display: flex;
    right: 0;
    left: 0;
    z-index: 10;
    margin: auto;
    width: 100%;
  }
  .main {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .image {
    width: 100%;
    height: 25vh;
    overflow: hidden;
    position: relative;
    background-image: url('../img/twoMen.jpg');
    background-size: cover;
    background-position: top center;
    z-index: 3;
  }
  .login {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #FFF;
    margin: 1em 0;
    z-index: 6;
    top: 0;
    width: 100%;
    margin: 1em auto;
    padding: 0 1.5em;
    .headline {
      width: 100%;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.1rem;
      margin: 0.375em auto 0.25em;
      color: @headlineColor;
      padding: 0.5em;
      font-size: @mobileJumbotranSize;
    }
    .footer {
      padding: 0 1em;
      text-align: center;
    }
    .fields-container {
      min-height: 32vh;
    }
    .inputs-container {
      max-width: 100%;
      margin: 0;
      .input-label {
        color: @headlineColor;
        letter-spacing: 0.02rem;
        font-weight: 500;
        display: block;
        margin: 0;
        position: relative;
        text-align: center;
        top: 0.5em;
      }
      .email-container {
        width: 100%;
        margin: auto;
      }
      .input-field-container,
      .submit-field-container {
        margin: 1em auto;
        input, .link {
          width: 450px;
          max-width: 100%;
          height: 3em;
          margin: 1em auto;
          border: 1px;
          display: block;
          padding: 0;
        }
        input {
          width: 325px;
          max-width: 100%;
          border: 1px solid @landingInputBorderColor;
        }
        input.field {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: 8px;
          border: 1px solid lighten(@headlineColor, 40%);
          border-radius: 0;
          background: @wellColor;
        }
        input.field:focus {
          outline: none;
        }
        .link {
          text-align: center;
          text-decoration: underline;
        }
      }
      #btnLogin {
        height: 3.5em;
      }
    }
  }
  .links {
    font-weight: bold;
    letter-spacing: 0.1rem;
    font-size: @mobileSize*0.99;
  }
  .copyright {
    font-weight: normal;
    letter-spacing: 0.1rem;
    opacity: 0.8;
  }

  footer {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 0;
    z-index: 3;
    .logo-footer {
      position: absolute;
      margin: 0.5em auto;
      left: 0;
      right: 0;
    }
  }
  #tnthNavWrapper {
    display: none;
  }
  .watermark {
    display: none;
  }
  #timeOutMessageContainer {
    margin: 0.5em auto 1.5em;
    padding: 0 1em;
    font-weight: 600;
    color:#a94442;
    max-width: 100%;
    text-align: center;
    letter-spacing: 0.01rem;
  }
}
@media (min-width: 520px) {
  body.landing {
    header {
      img {
        width: 150px;
      }
    }
    .image {
      height: 30vh;
      background-position: center 30%;
    }
    .links {
      font-size: @mobileSize;
    }
    .login {
      top: -7em;
      width: 70vw;
      .input-field-container {
        input {
          width: 375px;
        }
      }
    }
    footer {
      bottom: 7em;
    }
  }
}
@media (min-width: 992px) {
  body.landing {
    img.mobile {
      display: none;
    }
    img.desktop {
      display: block;
    }
    header {
      img {
        width: 180px;
        margin: 1em 1.5em;
      }
    }
    .main {
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
    }
    .image {
      background-position: 20% center;
      height: 100vh;
      position: relative;
      flex-grow: 2;
    }
    .login {
      top: 0;
      align-items: center;
      justify-content: center;
      form {
        min-height: 60vh;
      }
      .headline {
        width: 450px;
        max-width: 100%;
        text-align: left;
        padding: 0 1.5em 1em;
        font-size: @jumbotranSize;
      }
      .inputs-container {
        min-height: auto;
        .input-label {
          text-align: left;
          margin-left: 2.5em;
        }
        .link {
          text-align: left;
        }
        .submit-field-container {
          .link {
            text-align: left;
            padding: 0 2.5em;
          }
        }
      }
      .inputs-container {
        .input-field-container,
        .submit-field-container {
          input,
          .input-label {
            width: 375px;
          }
          .input-label {
            margin: auto;
          }
        }
      }
      .footer {
        position: absolute;
        bottom: 0;
        padding: 0 2.5em;
        right: 1.5em;
        left: 1.5em;
        text-align: left;
      }
    }
    footer {
      bottom: 1.5em;
      .logo-footer {
        left: 1.5em;
        bottom: 1.5em;
        right: auto;
      }
    }
    #timeOutMessageContainer {
      width: 82.5%;
      text-align: left;
    }
  }
}
@media (min-width: 1200px) {
  body.landing {
    .login {
      width: 45vw;
    }
  }
}
@media (min-width: 1650px) {
  body.landing {
    .login {
      .inputs-container {
        .input-label {
          margin-left: 5em;
        }
      }
      .footer {
        left: 3.5em;
        right: 3.5em
      }
    }
  }
}
@media (min-width: 1741px) {
  body.landing {
    #timeOutMessageContainer {
      width: 80%;
    }
  }
}
#forgetPasswordForm {
  min-height: 35vh;
  #email {
    height: @jumboInputHeight;
    width: 325px;
  }
}
#resetPasswordForm {
  min-height: 40vh;
  label {
    color: darken(@labelColor, 10%);
    font-weight: 400;
  }
  .item {
    margin-bottom: 1.5em;
  }
  input {
    outline: none;
  }
  input:focus {
    background: @inputFocusBgColor;
    outline: none;
  }
}
#socialMediaRegistrationContainer .btn-social,
#regForm input[type="submit"],
#socialMediaLoginContainer .btn-social,
#socialMediaLoginContainer input[type="submit"]
{
    margin: 0.2em auto;
    display: inline-block;
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    height: 52px;
    font-size: @mobileSize;
    line-height: 1;
    margin: 0 auto;
    letter-spacing: 3px;
    border-radius: 0px;
    padding: 1.2em 2em 1em 4em;
    text-transform: uppercase;
    text-align: left;
}
#regForm input,
#loginForm input {
  height: @jumboInputHeight;
  padding: 5px;
  border-radius: 0;
  width: 350px;
  max-width: 100%;
  margin: auto;
  display: block;
  box-shadow: none;
  border: 1px solid lighten(@mutedColor, 15%);
  outline: none;
  &:focus {
    outline: none;
    background: @inputFocusBgColor;
  }
}

#regForm input[type="submit"],
#loginForm input[type="submit"] {
   background-color: @btnColor;
   text-align: center;
   padding: 1.1em 2em 1em;
   height: 52px;
   display: block;
   margin: auto;
}
#regForm .divider,
#loginForm .divider {
  padding: 0.5em 0.75em;
  text-align: center;
}
@media (min-width: 416px) {
  #regForm input,
  #loginForm input,
  #socialMediaRegistrationContainer .btn-social,
  #regForm input[type="submit"],
  #socialMediaLoginContainer .btn-social,
  #socialMediaLoginContainer input[type="submit"] {
    width: 400px;
  }
}
.reg-title {
  font-size: @headlineSize;
  line-height: 33px;
  color: #494a45;
  position: relative;
  margin: 1.5em 0 1em;
  font-weight: bold;
}

.form___submit_container {
  position: relative;
  .icon {
    position: absolute;
    left: 45%;
    top: 25%;
    visibility: hidden;
    z-index: -1;
    -o-transition: visibility 150ms ease;
    -webkit-transition: visibility 150ms ease;
    transition: visibility 150ms ease;
  }
  .btn {
    -o-transition: visibility 150ms ease;
    -webkit-transition: visibility 150ms ease;
    transition: visibility 150ms ease;
  }
  &.active:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #FFF;
    opacity: @disabledOpacity;
    z-index: 1;
  }
  &.active {
    .icon {
      visibility: visible;
      z-index: 2;
    }
    .btn {
      visibility: hidden;
    }
  }
}

.login-form,
.register-form {
  .btn-social-container {
    position: relative;
    min-height: 45vh;
    a {
      text-decoration: underline;
    }
  }
  #homeFooter {
    display: block;
    width: 100%;
    max-width: 100%;
    border-top: 1px solid @HRColor;
    left: 0;
    right: 0;
    padding: 0 2em 1em;
  }
  #homeFooter.footer-separate {
    .footer-container {
      margin-top: 16px;
      border-top: 0;
      padding-top: 0;
    }
  }
  .footer-container {
    margin-top: 16px;
  }
}
.register-form {
  #regForm {
    position: relative;
    overflow: hidden;
    padding: 2em 0;
  }
}
.login-form {
  #loginForm {
    position: relative;
    overflow: hidden;
    padding: 10vh 0;
  }
}
@media (min-width: 992px) {
  .login-form {
    #loginForm {
      padding: 4vh 0;
    }
  }
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    @placeHolderColor;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    @placeHolderColor;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    @placeHolderColor;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    @placeHolderColor;
}
select:not([multiple]) {
  border: 1px solid @btnDefaultBorderColor;
  overflow: hidden;
  background-image: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  select:not([multiple]) {
      background-image: none !important;
      padding-right: 0.5em !important;
  }
}
/* Accordion styles */
.tabs-container {
  .tab {
    position: relative;
    margin-bottom: 1px;
    width: 100%;
    overflow: hidden;
  }
  input.tab-input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  label.tab-label {
    position: relative;
    display: block;
    padding: 0 0.5em 0 2.5em;
    color: #777777;
    border: 1px solid #b8babb;
    font-weight: normal;
    line-height: 3;
    cursor: pointer;
    width: 100%;
    max-width: 100%;
  }
  label.tab-label.active,
  label.tab-label:hover {
    color: @linkHover;
  }
  label.tab-label.active {
    font-weight: 500
  }
  //
  .tab-content {
    max-height: 0;
    overflow: auto;
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
    .registration-label {
      margin-top: 12px;
      margin-bottom: 0 !important;
    }
  }
  /* :checked */
  label.tab-label.active ~ .tab-content {
    max-height: 27em;
    margin: 1em auto;
    padding: 16px 16px;
    border: 1px solid #e4dfdf;
  }
  /* Icon */
  label.tab-label::after {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 3em;
    height: 3em;
    line-height: 3;
    text-align: center;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
  }
  input.tab-input + label.tab-label::after {
    content: "\25BC";
  }
  label.tab-label.active::after {
    transform: rotateX(180deg);
  }
}
#mainDiv.profile .tabs-container {
  margin-bottom: 1.5em;
}
#resourcesContentContainer {
  min-height: 50vh;
  .video {
    position: relative;
    width: 100%;
    padding-bottom: 56%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
  .tab-label {
    font-size: 1.1em;
    background: @featureBtnColor;
    color: #FFF;
    letter-spacing: 0.1em;
  }
  .tabs-container input.tab-input:checked+label.tab-label::after {
    visibility: hidden;
  }
  .tab-content {
    background: lighten(@featureBtnColor, 42%);
    border: 0;
    overflow: hidden;
    ul {
      padding: 1em 1em 0.5em;
    }
    li {
      line-height: 3rem;
      margin-left: 1em;
    }
  }
}
@media (min-width: 699px) {
  #resourcesContentContainer {
    .video {
      width: 45%;
      padding-bottom: 25.2%;
    }
  }
}
.work-instruction-title {
  background: @featureBtnColor;
  padding: 0.5em;
  color: #FFF;
  display: block;
  position: relative;
  & .pull-right {
    position:absolute;
    width: 125px;
    right:0;
    top: 2px;
    & span {
      display: inline-block;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 6px 12px;
      &:hover {
        cursor: pointer;
        background: darken(@featureBtnColor, 5%);
      }
    }
    & span:last-of-type {
      margin-left: 0.1em;
    }
    & a {
      color: #FFF;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.work-instruction-printicon {
  &:hover {
    cursor: pointer;
  }
}
.work-instruction-content-heading {
  margin-bottom: 1.5em;
}
.work-instruction-content {
  padding: 0 0.5em;
  max-width: 100%;
  overflow: auto;
}
#emproResourcesContentContainer {
  .tnth-headline {
    margin-bottom: 8px;
  }
  .intro {
    margin-bottom: 32px;
    font-size: @baseLargerFontSize;
    line-height: 1.5;
  }
  .title {
    padding: 12px;
    background-color: #f7f5f5;
  }
  .content {
    padding-left: 12px;
    padding-right: 12px;
    &.active .description:after {
      content: "Close Section X";
      width: 152px;
  	}
  }
  .description {
    line-height: 1.5;
    //margin-bottom: 24px;
    font-size: @baseLargerFontSize;
    width: 100%;
    &:after {
      display: block;
      content: "Play video  \f03d";
      font-family: Arial, sans-serif, FontAwesome;
      font-style: normal;
      font-size: 18px;
      transition: transform 0.35s ease;
      margin-top: 16px;
      color: #FFF;
      background-color: @emproBtnPrimaryColor;
      width: 132px;
      padding: 8px 0px 8px 12px;
    }
    &:hover {
      cursor: pointer;
      &:after {
        background-color: darken(@emproBtnPrimaryColor, 10%);
      }
    }
  }
  .video {
    position: relative;
    &:before {
      position: absolute;
      top: 25%;
      left: calc(~"50% - 35px");
      content: "Loading...";
      font-size: 20px;
      display:block;
      z-index: 100;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #FFF;
      content: "";
      display:block;
      z-index: 99;
    }
    &.video-hide {
      display: none;
    }
    &.loaded:after, &.loaded:before {
      display: none;
      z-index: -1;
    }
  }
  .item {
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 32px;
  }
  .portal-flex-container {
    padding-top: 4px;
    padding-bottom: 0;
    align-items: flex-start;
    flex-direction: column;
  }
  .collapsible {
    display: block;
    width: 100%;
    padding-left: 16px;
    padding-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid @muterColor;
    position: relative;
    font-weight: bold;
    cursor: pointer;
    &:last-of-type {
      border-bottom: 1px solid @muterColor;
      padding-bottom: 16px;
    }
    &:hover {
      color: @emproBtnPrimaryColor;
    }
    &:after {
        content: "\f107";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: 300;
        color: @mutedColor;
        display: block;
        position: absolute;
        right: 12px;
        top: 16px;
        font-size: 20px;
        transition: transform 0.35s ease;
    }
    & + .collapsible-content {
      display: none;

    }
    &.open {
      font-weight: bold;
      color: @emproBtnPrimaryColor;
      background-color: #F7F7F7;
      &:after {
        transform: rotate(180deg);
        color: @emproBtnPrimaryColor;
      }
      & + .collapsible-content {
        display: block;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
  .collapsible-content {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 32px;
  }
  .video {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 56.25%;
  }
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @media (min-width: 768px) {
    & {
      .item {
        padding-right: 0;
        padding-left: 0;
      }
      .description {
       // width: 50%;
        padding-right: 16px;
      }
      .video {
        width: 60%;
        padding-bottom: 33.756%;
      }
      .portal-flex-container {
        flex-wrap: nowrap;
      }
    }
  }
  @media (min-width: 1200px) {
    & {
      max-width: 1000px;
      margin: auto;
    }
  }
}
.form-control {
  height: @inputHeight;
  border-radius: 0;
}
.form-group label {
  font-weight: normal;
}
.feature-btn {
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  border: 2px solid @baseColor;
}
.feature-btn-primary {
  background-color: @bodyBgColor;
  color: @baseColor;
  &:hover, &:active {
    color: @featureBtnHoverColor;
    background-color: @linkHover;
  }
}
#socialMediaRegistrationContainer,
#socialMediaLoginContainer,
.social-divider-container
 {
  display: none;
}
#tnthNavWrapper {
  z-index: 20;
  position: relative;
  visibility: hidden;
  -webkit-transition: visibility 550ms ease 350ms;
  transition: visibility 550ms ease 350ms;
}
#iqFooterWrapper {
  display: none;
  border-top: solid 1px @HRColor;
  margin-top: 2em;
}
#mainHolder {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: visibility 0.6s, opacity 0.7s ease-in;
  transition: visibility 0.6s, opacity 0.7s ease-in;
}

// Footer styles
#homeFooter {
  overflow: auto;
  font-size: @smallerText;
  padding-top: 0.5em;
  padding-bottom: 1em;
  line-height: 1.2;
  display: none;
  text-align: left;
  &.footer-separate {
    padding-top: 0;
    margin-top: 1em;
    .footer-container {
      margin-top: 30px;
      border-top: 1px solid #ccc;
      padding-top: 1em;

      img {
        margin-bottom: 0.5em;
      }
    }
  }
  img {
    width: 40%;
  }
  .copyright {
    line-height: 1.5;
  }
  .logo-container {
    text-align: right;
  }
}
#footerLogo {
  display: inline-block;
  background-image: @defaultFooterLogoPath;
  background-size: 88px 100px;
  background-repeat: no-repeat;
  width: 88px;
  height: 100px;
  &.white-theme {
    background-image: @defaultFooterWhiteLogoPath;
    position: relative;
    top: 6px;
  }
}
#footerMovember {
  display: inline-block;
  background-image: @defaultFooterMovemberPath;
  background-size: 80px 100px;
  background-repeat: no-repeat;
  width: 80px;
  height: 100px;
  &.white-theme {
    background-image: @defaultFooterWhiteMovemberPath;
    background-size: 86px 86px;
    width: 86px;
    height: 86px;
  }
}
@media (min-width: 699px) {
  #homeFooter {
    img {
      width: auto;
    }
  }
}
#mainDiv.profile {
  min-height: 110vh;
  overflow: hidden;
  background: rgba(95, 103, 110, 1);
  z-index: -2;
  a.open,
  a.back {
    display: none;
  }
  .profile-header {
    .subheader {
      margin-top: 16px;
      line-height: 1.55;
    }
  }
  .detail-title {
    margin-bottom: 12px;
  }
 .detail-title {
    color: #ebefeb;
    letter-spacing: 1px;
    font-size: @subheadSize;
  }
  #profileHeader * {
    color: #FFF;
    letter-spacing: 1px;
  }
  div.bootstrap-table,
  #userSessionsListContainer {
    TH {
      background-color: @rowBackgroundColor;
      color: #FFF;
      padding: 0.25em 0.5em;
      font-weight: normal;
    }
  }
  tr.odd {
    background-color: #F9F9F9;
  }
  tr.even {
    background-color: #FFF;
  }
  .copyright-container *,
  .logo-container * {
    color: @footerWhiteThemeColor;
  }
  .copyright-container {
    font-size: @footerSize;
    color: @footerWhiteThemeColor;
    width: 100%;
  }
  .footer-container.flex {
    clear: both;
    display:flex;
    padding: 1em 0.8em;
    margin: 0 0.5em;
    border-top: 1px solid #ddd;
    justify-content: space-around;
  }
  #bdGroup {
    margin-bottom: 0.5em;
  }
  #phone, #email, #altPhone  {
    width: 300px;
    max-width: 100%;
  }
  #erroremail {
    color: @errorMessageColor;
  }
  #study_id_view {
    margin-top: 0.4em;
    margin-bottom: 0.4em;
  }
  .study-id-view td {
    margin-top: 0.4em;
  }
  #profileDeceasedSection {
    hr {
      border-bottom: 1px solid darken(@HRColor, 10%);
      margin-bottom: 2.25em;
    }
    .form-group {
      margin-bottom: 10px;
    }
  }
}

#deceasedConsentPopover {
  &:hover {
    cursor: auto;
  }
}

.deceased-popover-content {
  margin-top: 0.5em;
}

.deceased-popover-buttons-container {
  display: flex;
  justify-content: center;
  margin: 0.5em 0 0.25em;
  button {
    margin: 0.5em;
  }
}
#deceasedInfo {
  margin: 0.5em 0;
}

#mainDiv.profile .copyright-container,
body.portal .copyright-container,
body.portal .copyright-container * {
  color: @footerWhiteThemeColor;
}

/** Spacing for mainNav area so rest of page that doesn't get pushed down if portal_wrapper takes awhile to load **/
#mainNav {
  height: 71px;
  position: relative;
  background-color: @mainNavMobileBgColor;
  background-image: none;
  @media (min-width: 992px) {
    background-color: @mainNavBgColor;
    background-image: url('../../.././static/img/header_background_xs.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
}
#mainNavLoadingError {
  position: absolute;
  color: @errorMessageColor;
  bottom: 0;
  right: 0;
  left: 0;
  width: 250px;
  margin: auto;
  padding: 1em 0;
}
@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  /* IE10+ */
  #mainNav {
    height: auto;
  }
}

#notificationBanner {
  display: none;
  position: absolute;
  left: 0;
  z-index: 1000;
  background-color: @notificationBgColor;
  box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  right: 0;
  padding: 0;
  color: #FFF;
  .content {
    width: 100%;
    max-width: 100%;
    background-color: @notificationBgColor;
    margin: auto;
    letter-spacing: 1px;
    text-align: center;
  }
  .fa-info-circle {
    margin-right: 4px;
  }
  .notification-info {
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    margin: auto;
    letter-spacing: 1px;
    color: #FFF;
    &:hover {
      cursor: pointer;
      background-color: @notificationLinkHoverBgColor;
    }
  }
  .notification {
    display: none;
    max-width: 100%;
    padding: 2px;
    &:hover {
      cursor: pointer;
      background-color: @notificationLinkHoverBgColor;
    }
    p {
      margin: 0.1em auto;
      padding: 0.1em;
    }
  }
  .notification.active {
    display: block;
  }
  .notification[data-visited] {
    display: none;
  }
  .notification
  a {
    color: #FFF;
    text-decoration:underline;
    display: inline-block;
    &:hover {
      cursor: pointer;
      -webkit-transform: scale(1.02);
      transform: scale(1.02);
    }
  }
  &, span {
    font-size: 7pt;
  }
  .close {
    color: #000;
    position: fixed;
    z-index: 2001;
    right: 0;
    top: 0;
    margin-top: 4px;
    margin-right: 1em;
    display: none;
    &.active {
      display: block;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
div.right-panel {
  position:relative;
  max-width: 991px;
  transition:margin ease .5s;
  margin: auto;
}
div.footer-wrapper.right-panel {
  margin: auto;
}
.website-consent-script #termsText {
  display: none;
}
.hide-terms {
  display: none;
}
#termsContainer.website-consent-script #termsText {
  padding: 1em 2.5em 2.5em 2.5em;
  display: block;
}
#termsText {
  padding: 0;
}
.terms-text {
  padding: 16px 24px 16px;
  .title {
    width: 90%;
    text-align: center;
    margin: auto;
    line-height: 1.4;
    font-weight: 600;
  }
  p {
    line-height: 1.5;
  }
}
@media (min-width: 699px) {
  .terms-text {
    padding: 8px 32px 8px;
    .title {
      width: 64%;
    }
  }
}
#agreeLabel {
  margin-left: 2em;
}
#termsText.agreed {
  display: none;
}
.terms-of-use-intro {
  display: none;
}
.data-saving-indicator {
  position: absolute;
  bottom: 0;
  left: 2.25em;
  margin: 1.5em 0 0.5em;
  padding: .5em 0;
  z-index: 5;
  .indicator-icon {
    margin-right: 8px;
  }
}
#topTerms {
  position: relative;
  .well {
    position: relative;
  }
  .save-info {
    top: 4px;
    left: 12px;
  }
  .well {
    margin-top: 1em;
  }
  .custom-tou-text a {
    text-decoration: underline;
  }
  .terms-checkbox-container {
    margin-top: 16px;
    padding: 16px 0;
    border-top: 2px solid @muterColor;
    .terms-label {
      font-size: 1em;
    }
  }
  .terms-checkbox-container i:hover {
    cursor: pointer;
  }
  .required-link {
    font-weight: normal;
    text-decoration: underline;
  }
  #termsCheckbox[data-reconsent] {
    label.terms-label[data-agree='true']:not([current]) {
      display: none !important;
    }
  }
}

.terms-tick-box {
  float: left;
  width: 2%;
  margin: 0.3em 0.5em 0 0;
  &:hover {
    cursor: pointer;
  }
}
.terms-tick-box-text {
  width: 93.5%;
  max-width: 100%;
  margin-left: 2px;
  display: inline-block;
  font-weight: 600;
}
.edit-view {
  .terms-tick-box-text {
    margin-left: 4px;
  }
}
.website-consent-script {
  .terms-tick-box-text {
    width: 95%;
  }
  .data-saving-indicator {
    position: relative;
  }
}
#termsCheckbox {
  width: 1000px;
  max-width: 100%;
  margin-top: 2em;
  position: relative;
  .display-view {
    font-weight: normal;
    display: none;
    margin: 1em auto;
  }
  a.form-link {
  text-decoration: underline;
  }
}
.box-consent-container {
  width: 300px;
  max-width: 100%;
  border-top: solid 1px #000;
}
.continue-msg-wrapper {
  margin: 1em auto;
  display: none;
}
.terms-container {
  border-style:ridge;
  max-height: 250px;
  overflow: auto;
  padding: 1em;
  background-color:@wellColor;
}

#topTerms ~ #aboutForm {
  min-height: 50vh;
}

#aboutForm {
  max-width: 100%;
  padding: 1em;
  margin: 2em auto;
  min-height: 50vh;

  .view-container {
    display: none;
  }

  .reg-buttons-msg-wrapper {
    max-width: 100%;
  }
  .reg-complete-container {
    width: 350px;
    max-width: 100%;
    margin: 1em auto;
    padding: 0.5em 0;
    text-align: center;
    display: none;
    .update-button-container {
      margin-top: 32px;
      position: relative;
      .loading-message-indicator {
        min-height: 56px;
      }
    }
  }
  #buttonsContainer {
    max-width: 100%;
    margin: 24px auto;
    position: relative;
    .loading-message-indicator {
      position: absolute;
      top: 8px;
      left: 8px
    }
  }
  #buttonsContainer.continue {
    margin: 32px 0;
  }
  #next {
    opacity: 0;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding: 1em;
    max-width: 100%;
    margin-left: 0.2em;
    transition: opacity 350ms ease;
  }
  #updateProfile {
    opacity: 0;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding: 1em;
    width: 320px;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 160px);
  }
  #next:disabled {
    background: @disabledColor;
    border: 1px solid @disabledColor;
  }
  #next.open, #updateProfile.open {
    opacity: 1;
  }
  #next span {
    margin-left: 0.3em;
    margin-right: 0.3em;
  }
  .iq-container {
    font-family: "akzidenz_grotesk_medium", Arial, "Helvetica Neue", Helvetica, sans-serif;
    width: 100%;
    min-height: 30vh;
    display: none;
  }
  .iq-container .tnth-headline:first-of-type {
    transform: translateY(-100%);
    transition: transform 250ms ease-out .7s;
    font-weight: bold;
    color: #4B5E6F;
    color: rgba(57, 56, 51, 0.8);
    letter-spacing: 0.1px;
    text-align: center;
    margin: 1.2em 0.3em 1.5em 0.3em;
  }
  .iq-container.open .tnth-headline:first-of-type {
    transition: transform 250ms ease-out .1s;
    transform: translateY(0);
  }
  .iq-container .content {
    position: relative;
  }
  .iq-container .content-body {
    padding: 0;
    margin: 16px auto;
    .terms-checkbox-container {
      margin-top: 16px;
      padding: 16px 16px;
      border-top: 2px solid @muterColor;
    }
  }
  @media (min-width: 699px) {
    .iq-container .content-body {
      .terms-checkbox-container {
        padding: 16px 24px;
      }
    }
  }
  .iq-container .subtitle {
    color: #595d4e;
    margin: 1em auto;
    font-size: @subheadSize;
    position: relative;
  }
  .iq-container #clinics {
    margin-top: 2em;
  }
  input[type='text'],
  select,
  .form-group label,
  label,
  .pat-label {
    font-size: @subheadSize;
  }
  .terms-label {
    font-size: 1em;
  }
  .prompt {
    color: @labelColor;
    font-size: @subheadSize;
  }
  #nameGroup {
    margin-top: 2.5em;
    position: relative;
  }
  .bd-optional {
    display: none;
  }
  .iq-container .heading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    color: #fff;
    display: block;
    height: 6em;
    line-height: 58px;
    background-color: #FFF;
    background: -moz-linear-gradient(to bottom, #FDFDFD , rgba(203, 210, 217, 0.3));
    background: -webkit-linear-gradient(to bottom, #FDFDFD , rgba(203, 210, 217, 0.3));
    background: linear-gradient(to bottom, #FDFDFD , rgba(203, 210, 217, 0.3));
    border-bottom: 1px solid #FBFBFA;
    border-top: 1px solid rgba(203, 210, 217, 0.2);
    border-left: 1px solid rgba(203, 210, 217, 0.2);
    font-size: @subheadSize;
  }
}
#topTerms, #aboutForm {
  .save-loader-wrapper {
    display: none;
  }
}
#iqRefresh {
  .refresh-icon {
    margin-right: 8px;
  }
  &:hover {
    cursor: pointer;
    color: darken(@errorMessageColor, 5%);
  }
}
#iqErrorMessage {
  position: relative;
  top: 4em;
}
#progressWrapper {
  display: none;
  &.initial-queries {
    display: none !important;
  }
  width: 100%;
  margin: 4% auto 9% auto;
  position: relative;
  .progressbar {
    counter-reset: step;
    position: relative;
    left: -5%;
    li {
      list-style-type: none;
      width: 20%;
      float: left;
      font-size: 10px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: #7d7d7d;
    }
    li:before {
      width: 34px;
      height: 34px;
      content: counter(step);
      counter-increment: step;
      line-height: 30px;
      border: 4px solid #7d7d7d;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
    }
    li:after {
      width: 100%;
      height: 4px;
      content: '';
      position: absolute;
      background-color: #7d7d7d;
      top: 15px;
      left: -50%;
      z-index: -1;
    }
    li:first-child:after {
      content: none;
    }
    li.active {
     color: green;
    }
    li.active:before {
      border-color: #55b776;
    }
    li.active + li:after {
      background-color: #55b776;
    }
  }
}
.orglist-download-container {
  display: inline-block;
  div {
    margin-left: 0.2em;
    margin-bottom: 0.5em;
  }
  a {
    color: @linkColor;
  }
}

.bootstrap-table {
  .pull-right {
    .btn, button {
      padding: 0.57em;
    }
  }
  div.columns {
    top: 4px;
  }
  div.search {
    top: 4px;
  }
}


.admin-table {
   .search .form-control {
    height: 42px !important;
    width: 150px;
    max-width: 100%;
  }
  &.smaller-text {
    .search .form-control {
      height: 3.45em !important;
    }
  }
  .deleted-button-cell {
    vertical-align: middle;
  }
  div.pull-right {
    width: 100%;
    max-width: 100%;
    padding: 0 0.2em 0.2em;
    margin-left: 0;
    button {
      padding: 0.73em !important;
      min-height: 42px;
    }
  }
  div.pull-right.search {
    width: 50%;
    float: left !important;
    max-width: 100%;
  }
  th.id-field div.th-inner,
  th.organization-field div.th-inner {
    position: relative;
  }
}
#exportReportContainer {
  display: inline-block;
  button {
    min-width: 60px;
    margin-left: 6px;
    border-radius: 0;
    min-height: 42px;
    cursor: pointer;
  }
  .dropdown-menu {
    top: 94%;
  }
}
#exportReportPopoverContent + .popover {
  left: 0 !important;
  & .arrow {
    left: calc(~"100% - 32px") !important;
  }
}
.exportReport__popovercontent {
  top: 4px;
  position: relative;
  overflow: hidden;
  min-width: 160px;
}
.exportReport__popovercontent,
#dataDownloadModal {
  .exportReport__display-container,
  .export__display-container {
    .exportReport__retry{
      padding: 0.5em 0.7em;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  .exportReport__loader,
  .export__loader {
    margin-right: 8px;
    position: relative;
    top: 4px;
  }
  .exportReport__status,
  .export__status {
    display: inline-block;
    position: relative;
    padding-top: 4px;
    padding-bottom: 4px;
    &:empty {
      padding-top: 0;
      padding-bottom: 0;
    }
    &.active {
      min-width: 50px;
      min-height: 50px;
    }
  }
  .exportReport__status.active:before,
  .export__status.active:before {
    position: absolute;
    animation: borderLoader 750ms infinite linear;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    top: 24px;
    width: 10px;
    height: 10px;
    background: darken(@btnDefaultBorderColor, 5%);
    border-radius: 100%;
    opacity: 0.6;
  }
  .exportReport__error,
  .export__error {
    .message {
      color: @errorMessageColor;
    }
  }
  .exportReport__error,
  .exportReport__history {
    margin-bottom: 16px;
  }
  .export__history {
    margin-top: 16px;
    border-top: 1px solid @HRColor;
    padding-top: 8px
  }
}
#dataDownloadModal {
  .exportReport__display-container,
  .export__display-container {
    display: none;
    margin-top: 0;
    &.active {
      display: block;
    }
  }
  .exportReport__status.active:before,
  .export__status.active:before {
    top: 28px;
  }
}
@keyframes borderLoader {
  0% {
      left: 0;
  }
  50% {
      left: 50%;
  }
  100% {
      left: 100%;
  }
}
#longitudinalReportContainer {
  margin-top: 16px;
}
#timeWarpContainer {
  cursor: pointer;
  .error-message {
    margin-top: 16px;
  }
  .prompt .edit-icon {
    margin-right: 8px;
  }
  .text {
    line-height: 2;
  }
  .text:hover {
    color: darken(@mutedColor, 10%);
  }
  .button-container {
    position: relative;
    .loading-message {
      display: none;
      position: absolute;
      top: 0;
      min-height: 40px;
    }
  }
}
#timeWarpModal {
  input {
    width: 64px;
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
  }
  .body-content {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  #timeWarpModal {
    .modal-dialog {
      width: 480px;
    }
  }
}
.fixed-table-toolbar .btn-group>.btn-group:first-child>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
#adminTableContainer {
  position: relative;
  opacity: 0;
  -webkit-transition: opacity 600ms ease;
  transition: opacity 600ms ease;
  caption {
    font-weight: normal;
    padding: 12px 12px 8px;
    border-bottom: 1px solid @HRColor;
    label {
      font-weight: normal;
      letter-spacing: 0.05rem;
    }
  }
  .fixed-table-loading {
    background-color: hsla(0, 0%, 100%, 0.7);
    max-height: 2500px;
    overflow: hidden;
    -webkit-transition: max-height 550ms ease 150ms;
    transition: max-height 550ms ease 150ms;
  }
  .fixed-table-container {
    border: 1px solid darken(@HRColor, 5%);
    thead th:not(:first-of-type),
    tbody td:not(:first-of-type) {
      border-left: 1px solid darken(@muterColor, 15%);
    }
  }
  .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .fixed-table-toolbar .btn-group>.btn-group:last-child>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .fht-cell,
  .filterControl {
    opacity: 0;
    -webkit-transition: opacity 650ms ease 500ms;
    transition: opacity 650ms ease 500ms;
  }
  &.active {
    opacity: 1;
    .fht-cell,
    .filterControl {
      opacity: 1;
    }
  }
  &:before {
    content: " ";
    display: block;
    position: absolute;
    opacity: 1;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: hsla(0, 0%, 100%, 0.7);
    transition: opacity 0.5s ease-in;
  }
  &.active:before {
    opacity: 0;
    z-index: -1;
  }
}
#adminTableContainer:before {
  content: " ";
  display: block;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: hsla(0, 0%, 100%, 0.7);
}
#adminTable {
  min-height: 280px;
  transition: opacity 0.5s ease-in;
  border: 1px solid darken(@HRColor, 2%);
  .check-role-container {
    position: relative;
    .loading-indicator {
      position: absolute;
      left: 42%;
      background: hsla(0, 0%, 100%, 0.35);
      z-index: 10;
      display: none;
    }
    input:hover {
      cursor: pointer;
    }
  }
  .organization-field {
    min-width: 150px;
  }
  td.interventions-field {
    min-width: 180px;
    max-width: 100%;
  }
  .truenth-id-label {
     display: none;
  }
  div.card-view {
    display: flex;
  }
  .filterControl {
    .form-control.active {
        border: 2px solid darken(@muterColor, 15%);
        font-weight: 500;
    }
  }
  tr.loading {
    opacity: 0.4;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      z-index: 5;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      background: transparent;
    }
  }
  tr[data-uniqueid]:hover {
   background-color: @HRColor;
   cursor: pointer;
   td {
    cursor: pointer;
   }
  }
  tr[data-test-role] {
    color: @warningColor;
  }
  TH {
    background-image: -moz-linear-gradient(to bottom, #FFF, rgba(232, 233, 234, 0.9));
    background-image: -webkit-linear-gradient(to bottom, #FFF, rgba(232, 233, 234, 0.9));
    background-image: linear-gradient(to bottom, #FFF, rgba(232, 233, 234, 0.9));
    letter-spacing: 0.1px;
    border-bottom: 1px solid #dad8d8;
  }
  .truenth-id-label {
    display: none;
  }
  div.filterControl {
    min-height: 2.5em;
  }
  div.filterControl select,
  div.filterControl input
  {
    font-weight: normal;
    height: 2.5em;
  }
  th.status-field {
    position: relative;
  }
  th.status-field .loading-message-indicator {
    position: absolute;
    top: 50%;
    left: 2%;
    display: none;
    opacity: 0.6;
    font-size: @smallerText;
  }
  th.visit-field {
    min-width: 150px;
  }
  tr.deleted-user-row {
    td {
      opacity: @disabledOpacity
    }
    td.deleted-button-cell {
      opacity: 1;
      & .text-display {
        opacity: @disabledOpacity;
      }
      & .reactivate-icon {
        opacity: 1;
        margin: 0 0.5em;
        border: 1px solid #ececec;
        padding: 8px;
        &:hover {
          background: #FFF;
        }
      }
    }
  }
  .popover {
    .popover-content {
      padding: 12px 14px 4px;
    }
    .buttons-container {
      display: flex;
      justify-content: center;
      margin: 0.5em 0;
      .btn {
        font-size: 0.8em;
        margin: 0.5em 0.25em;
      }
    }
  }
}
.truenth-id-label {
   display: none;
 }

.filter-popover {
  margin-top: -6px !important;
}

#adminTableToolbar {
  button {
    background-image: linear-gradient(to bottom, #FFF, #ececec 90%);
    min-width: 220px;
    border: 2px solid darken(@muterColor, 5%);
  }
  .orgs-filter-warning {
    display: inline-block;
    margin: 0 8px;
    letter-spacing: 0.1rem;
    font-size: 0.95em;
    opacity: 0.8;
    position: relative;
    img {
      width: 22px;
    }
    &:after {
      content: "";
      position: absolute;
      top: 20%;
      left: 0;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      display: block;
      z-index: 1;
    }
    &:hover:after {
      background: hsla(0, 0%, 0%, 0.2);
    }
  }
  div.orglist-selector {
    display: block;
    @media (min-width:768px) {
      display: inline-block;
      top: 1px;
    }
    max-width: 100%;
    legend {
      letter-spacing: 1px;
      font-weight: 500;
      border-bottom: 0;
      font-size: @mobileSize;
    }
    .indent {
      padding: 0 0.2em;
    }
    .divider {
      position: relative;
      top: 0.5em;
    }
    div.dropdown-menu {
      max-width: 100%;
      min-width: 200px;
      #userOrgs {
        width: 100%;
        max-width: 100%;
        margin: 0 0.5em;
        input {
          cursor: pointer;
          position: relative;
          left: -6px;
          top: 0.5px;
          vertical-align: top;
        }
      }
      #fillOrgs {
        .divider {
          display: none;
        }
      }
    }
    div.org-container {
      display: block;
      * {
        font-size: @mobileOrgSize;
      }
      label.org-label {
        &.selected {
          font-weight: 500;
        }
        display: inline-block;
        padding: 0 1em;
        line-height: 1.6em;
        span {
          max-width: 250px;
        }
      }
      label.text-muted {
        color: #585a5a;
      }
      label span {
        display: inline-block;
      }
    }

    label.text-muted ~ div.org-container {
      margin-left: 1em;
    }

    label.text-muter {
      & ~ div.org-container {
        margin-left: 1.5em;
      }
    }

    label.disabled {
      opacity: @disabledOpacity;
    }

    #orglist-footer-container {
      padding: 0.5em 1em;
      width: 300px;
      display: flex;
      justify-content: space-between;
      max-width: 100%;
      margin: 0.75em auto 1em;
      position: relative;
      top: 1em;
      label {
        font-weight: normal;
        margin: 2px;
        line-height: 1.1em;
        text-align: center;
      }
      input[type="checkbox"] {
        position: relative;
        top: 0;
        left: -2px;
      }
    }
  }
}

#staffList {
  .fixed-table-toolbar {
    #adminTableToolbar {
      float: right;
    }
  }
}

/* reporting dashboard styling */
.nav-tabs.rd-nav>li.active>a,
.nav-tabs.rd-nav>li.active>a:focus,
.nav-tabs.rd-nav>li.active>a:hover {
    background-color: #6f7984;
    color: #FFF;
}
.rd-content-container {
    min-height: 50vh;
}
.stats-container {
    padding: 1em;
    display: none;
   .columns-right .btn {
      padding: 0.6em;
   }
}
.stats-container.active {
   display: block;
}
.stats-info {
    background-color: #F7F7F7;
    padding: 1em;
    margin: 1em auto;
}
.stats-table {
  .filterControl .form-control {
    font-weight: normal;
    max-width: 100%;
  }
}
#patientList .fixed-table-body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

#patientList .fixed-table-body::-webkit-scrollbar-track {
  background:#FFF;        /* color of the tracking area */
}

#patientList .fixed-table-body::-webkit-scrollbar-thumb {
  background-color:  lighten(@featureBtnColor, 25%);    /* color of the scroll thumb */
  border-radius: 24px;       /* roundness of the scroll thumb */
  border: 3px solid #FFF;  /* creates padding around scroll thumb */
}
#patientList {
  margin-top: 16px;
  position: relative;
  .intervention-actions-field {
    .cta {
      font-weight: 500;
      color: @alertColor;
      letter-spacing: 0.03rem;
    }
  }
  @media (min-width: 768px) {
    & {
      margin-top: 80px;
    }
  }
  @media (min-width: 992px) {
    & {
      margin-top: 16px;
    }
  }
  .search {
    top: 0;
    margin-top: 14px;
  }
  @media (min-width: 992px) {
    .search {
      margin-top: 0;
    }
  }
  @media (min-width: 1200px) {
    .search {
      margin-top: 10px
    }
  }
  #adminTableToolbar {
    border: 1px solid @toolbarBorderColor;
    padding: 4px 8px 6px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  .popover-content {
    padding: 8px 12px;
  }
  #cacheResetContainer {
    display: inline-block;
    #cacheResetForm {
      margin: 0.5em 0;
      background: lighten(@muterColor, 3%);
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 4px;
      .description {
        font-size: @mediumText;
        margin-bottom: 1em;
      }
      .button {
        margin-left: 4px;
        font-size: @mediumText * 0.89;
        font-weight: 500;
      }
      .byline-text {
        display: block;
        font-weight: 500;
        color: darken(@mutedColor, 10%);
        letter-spacing: 0.04rem;
        margin-left: 4px;
        font-size: 0.875em;
      }
    }
  }
  @media (min-width: 768px) {
    #patientList {
      #adminTableToolbar {
        padding: 0;
      }
    }
    #cacheResetContainer {
      margin-left: 8px;
      #cacheResetForm {
        margin: 0;
        padding: 8px 8px 8px 4px;
        .byline-text {
          display: inline-block;
          position: relative;
        }
      }
    }
  }

  .patientList-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    .patientlist-toggle-container {
      padding: 12px 16px;
      border: 2px solid darken(@muterColor, 5%);
      position: relative;
      &.loading{
        border: 1px solid @toolbarBorderColor;
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #FFF;
          opacity: 0.6;
          z-index: 10;
          content: "";
          display: block;
        }
        &:before {
          position: absolute;
          animation: borderLoader 1000ms infinite linear;
          display: block;
          content: " ";
          bottom: 0;
          left: 0;
          width: 12px;
          height: 2px;
          background: darken(@inputBorderColor, 5%);
          //border-radius: 100%;
          z-index: 14;
        }
      }
      a:hover, a:active, a:focus {
        text-decoration: none;
      }
      .radio {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin: 0;
        cursor: pointer;
      }
      .label {
        color: @labelColor;
        padding: 0;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle;
        letter-spacing: 0.03rem;
        &:first-of-type {
          margin-right: 8px;
        }
      }
      .radio:checked + .label {
        font-weight: 600;
      }
    }
  }

  #chkDeletedUsersFilter {
    margin: 0 0 0 4px;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
  }
  .deletedUsersFilterContainer {
    margin: 1em .25em;
    display: inline-block;
    padding: 0.5em 1em;
    line-height: 1.2;
    vertical-align: middle;
    border: 1px dotted @HRColor;
    font-size: @smallText;
    opacity: 0.8;
    .text {
      color: @btnDefaultColor;
      font-weight: normal;
      margin: 0 8px;
      display: inline-block;
      vertical-align: middle;
      letter-spacing: .1rem;
    }
  }
  div.or {
    display: inline-block;
    background-color: @orBackgroundColor;
    margin: 1.5em 1em 1em;
    font-size: @xxsmallText;
  }
  #_downloadLink {
    visibility: hidden;
  }
}
#chkDeletedUsersFilter {
  margin: 0 0 0 4px;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
}
.deletedUsersFilterContainer {
  margin: 1em 0.25em;
  display: inline-block;
  padding: .5em 1em;
  line-height: 1.4;
  vertical-align: middle;
  border: 1px dotted @HRColor;
  .text {
    color: @sectionTitleColor;
    font-weight: normal;
    margin: 0 8px;
    display: inline-block;
    vertical-align: middle;
    letter-spacing: .1rem;
  }
}
@media (min-width: 705px) {
  #patientList {
    .deletedUsersFilterContainer {
      margin: 0.5em 1.25em 0;
    }
  }
  #staffList {
    .deletedUsersFilterContainer {
      margin: 0;
    }
  }
}
#staffList {
  #staffListOptions {
    border-bottom: 1px solid @HRColor;
    padding-bottom: 16px;
    margin-top: 32px;
  }
  div.or {
    display: inline-block;
    background-color: @orBackgroundColor;
    margin: 1.5em 1em 1em;
    font-size: @xxsmallText;
  }
  #_downloadLink {
    visibility: hidden;
  }
}
#patientListOptions,
#staffListOptions {
  display:flex;
  flex-direction: column;
  margin-bottom: 0.5em;
}

#patientListOptions {
  padding: 0.5em 0;
}
#patientListOptions.disabled {
  height: 40px;
}
.download-wrapper,
.download-break {
    display: none;
}
#dataDownloadModal {
  .modal-dialog {
    max-width: 500px;
  }
  .modal-body {
    padding: 24px 24px 16px;
  }
  div.checkbox label{
    font-size: @modalInputSize;
  }
  label.radio-inline {
    font-size: @modalInputSize;
  }
  input[type="radio"] {
    position: relative;
  }
  .prompt {
    font-weight: 500;
    display: block;
    clear: both;
  }
  label.active {
    font-weight: 500;
  }
  .data-types-container {
    border-top: 1px solid @muterColor;
    padding-top: 8px;
  }
  .list-selector {
    float: right;
    border: 2px solid @muterColor;
    padding: 4px 8px;
    margin-top: -12px;
    margin-bottom: 4px;
    .items {
      display: flex;
      .item input {
        cursor: pointer;
      }
    }
    .item:first-of-type {
      margin-right: 12px;
    }
    .text {
      font-size: 12px;
      display: inline-block;
      position: relative;
      top: -2px;
      color: @mutedColor;
      letter-spacing: 0.03rem;
      &.active {
        font-weight: 500;
      }
    }
  }
}
#patientAssessmentDownload {
  display: inline-block;
  min-height: 1.2em;
}
#patientsInstrumentListWrapper {
  position: relative;
  padding: 4px 0 8px 0;
}
#patientsInstrumentList {
  opacity: 0;
  transition: opacity 250ms ease-in;
  clear: both;
  .list {
    display: flex;
    flex-direction: column;
    height: 200px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .title {
    margin-top: 16px;
    margin-bottom: 4px;
    font-size: 12px;
    color: @mutedColor;
    font-weight: 500;
  }
  .instrument-container {
    margin: 4px 0;
  }
  &.ready {
    opacity: 1;
    & + #instrumentListLoad {
      opacity: 0;
      z-index: -1;
    }
  }
}
#instrumentListLoad {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1.5em;
  opacity: 1;
  z-index: 1;
  transition: opacity 250ms ease-out;
}
.instrument-container label {
  min-width: 100px;
  max-width: 100%;
  display: inline-block;
}

#patientsDownloadTypeList {
  margin-top: 0.5em;
}

#_downloadMessage {
  color: @errorMessageColor
}

// Used for admin tables or other place where we don't need the large font size
.smaller-text {
  font-size: @smallerText;
  .form-control {
    font-size: @smallerText;
    height: @smallerText * 2;
  }
  .btn {
    font-size: @smallText;
  }
}

// Used for admin tables or other place where we don't need the large font size
.medium-text {
  font-size: @mediumText;
  .form-control {
    font-size: @mediumText;
  }
  .btn {
    font-size: @smallText;
  }
}
// For table rowlinks
.table.rowlink,
.table .rowlink {
  td:not(.rowlink-skip) {
    cursor: pointer;
    a {
      color: inherit;
      font: inherit;
      text-decoration: inherit;
    }
  }
}
.table-title {
  font-weight: bold;
  font-size: @baseFontSize;
  line-height: @baseFontSize * 1.33;
}

// fullsize background image box - used on homepage and similar pages
#fullSizeBox {
  a[href*="register"] {
    display: none;
  }
  a[href^="/go"] {
    display: none;
  }
  a[href*="google"] {
    display: none;
  }
  a[href*="facebook"] {
    display: none;
  }
  a[href*="about"]{
    display: none;
  }
  span.separator {
    display: none;
  }
}

#fullSizeBox {
  position: relative;
  text-align: left;
  margin: auto;
  background-image: url('../img/twoMen.jpg');
  background-size: cover;
  background-position: 30%;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height:680px;
   -webkit-transition: height 0.8s ease-in;
  -moz-transition: height 0.8s ease-in;
  transition: height 0.8s ease-in;
  .headline,
  .headline * {
    letter-spacing: 1px;
  }
  &.box-min-500 {
    min-height: 500px;
    max-height: 80vh;
    margin: auto;
    overflow: hidden;
  }
  &.box-min-400 {
    min-height: 400px;
  }
}
#fullSizeContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 18px 0 18px;
}
#fullSizeLogo {
  height: 70px;
}
#wellContainer {
  height: 100%;
  padding: 18px 18px 70px;
}
.tnth-well {
  margin: 0;
  padding: 18px;
  background-color:rgba(255, 255, 255, 0.7);
  color: #444;
  height: 60%;
  text-align: center;
  position: relative;
  .form-group {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}
.tnth-well-close {
  position: absolute;
  top: 0;
  right: 6px;
}
.tnth-splash {
  color: #fff;
  height: 100%;
  text-align: center;
  position: relative;
  .headline {
    font-size: 20px;
    line-height: 1.1;
    text-shadow: black 0px 0px 10px;
    margin: -1em 0 1em;
  }
  .feature-btn-primary {
    margin-top: 0.5em;
    font-weight: bold;
    padding: 6px 24px;
  }
}
.tnth-splash-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  color: #fff;
  margin-bottom: 10px;
}
.tnth-splash-bottom-link {
  color: #fff;
  padding: 8px;
  font-size: 18px;
  text-shadow: black 0px 0px 10px;
  &.separator {
    margin: 0 12px;
    border-left: 1px solid #fff;
    padding-left: 0;
    padding-right: 0;
  }
  &:hover, &:active, &:focus {
    color: #ddd;
  }
}
#wellContent {
  position: absolute;
  top: 35%;
  left: 50%;
  // TODO - Add browser specific styles, check compatibility
  transform: translate(-50%, -50%);
  .tnth-splash & {
    width: 100%;
  }
}
.profile-img {
  display: none;
  margin-top: 10px;
  margin-left: 6px;
  img {
    width: 60px;
    border-radius: 45px;
  }
}
body.portal {
  background: #5F676E;
  overflow-x: hidden;
  overflow-y: auto;
  &.modal-open {
    overflow-y: hidden;
  }
}
body.portal .container {
  max-width: 1100px;
}
body.profile .copyright-container,
body.portal .copyright-container  {
  color: #F5F5F5;
  margin-bottom: 1em;
}
.portal-body {
  margin-top: 1em;
  min-height: 60vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  .loading-container {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(75, 83, 91, 0.3);
    width: 100%;
    height: 100%;
    .content {
      color: #FFF;
      position: fixed;
      left: calc(~"50% - 50px");
      top: calc(~"50% - 50px");
      font-size: 1.5em;
      letter-spacing: 0.1rem;
      z-index: 5;
    }

  }
}
.portal-item {
  width: 100%;
  max-width: 100%;
}
.portal-header-container {
  color: #FFF;
  width: 87.5%;
  margin: 1em auto 32px auto;
  font-size: @subheadSize;
  max-width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .portal-header-container {
    margin: 3.5em auto 32px auto;
  }
}
@media (min-width: 992px) {
  .portal-header-container {
    margin: 32px auto 32px auto;
    max-width: 70%;
  }
}
.button-callout {
  position: relative;
}
.button-callout figure {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 2.5em;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  cursor: pointer;
}
.button-callout figure::after {
  content: "\EA03";
  display: inline-block;
  font-family: "symbols";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}
.portal-header-container * {
  line-height: 1.5;
  letter-spacing: 1px;
  font-weight: 400;
}
.portal-header,
.portal-intro-text {
  font-size: @subSmallHeadSize*0.96;
  letter-spacing: 1px;
  p {
    margin: 0 0 8px;
  }
}
@media (min-width: 699px) {
  .portal-header,
  .portal-intro-text {
    font-size: @subSmallHeadSize*0.96;
  }
  .portal-intro-text {
    padding-left: 32px;
    padding-right: 32px;
    line-height: 1.55;
  }
}
.portal-header-logout-container {
  position: relative !important;
  width: 100%;
  max-width: 100%;
  margin: auto;
  margin-bottom: 2em;
  text-align: center;
  & a {
    padding: 16px;
    min-width: 250px;
    max-width: 100%;
    margin: 1em auto;
    text-transform: uppercase;
  }
}
.portal-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 1em;
}

.portal-description {
  background: #3d4750;
  color: #FFF;
  padding: 0.5em 1.35em 2.5em;
  position: relative;
  width: 420px;
  max-width: 100%;
  margin: 1em;
  min-height: 250px;
  overflow: hidden;
  line-height: 1.5;
  align-self: stretch;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.disabled {
    position: relative;
    a:hover {
      text-decoration: none;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      content: "";
      background: #3d4750;
      opacity: 0.3;
      z-index: 1;
    }
  }
  & .title {
    font-size: @subSmallHeadSize;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  & a {
    color: #FFF;
    &.btn-lg {
      padding: 12px 16px;
      font-size: 0.95em;
    }
  }
  .button-container {
    a {
      width: 250px;
      max-width: 100%;
    }
  }
}
.portal-description-wrapper {
  margin-top: 32px;
  width:440px;
  .title {
    text-align: center;
    margin-bottom: 24px;
    color: #FFF;
    font-size: @lgHeadlineSize;
  }
  .portal-description {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .portal-description {
    margin: 1em;
    //width: 40%;
    &.full-width {
      width: 75%;
      min-height: 200px;
    }
  }
  .portal-description-wrapper {
    width: 42.5%;
  }
}

.portal-no-description-container {
  position: relative;
  font-size: @subheadSize;
  max-width: 100%;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  padding: 1.25em 2em;
  & .portal-description-title {
    font-size: @baseFontSize;
    font-weight: bold;
  }
}
@media (min-width: 992px) {
  .portal-no-description-container {
    & .portal-description-title {
      font-size: 1em;
    }
  }
}

.portal-description-body {
  text-align: center;
  font-size: @baseFontSize;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  .button-container {
    align-self: flex-end;
  }
  .error-message {
    color: lighten(@errorMessageColor, 35%);
    margin: 16px 40px 0px;
    font-weight: 600;
  }
}
.portal-flex-container .button-container,
.portal-description .button-container {
  position: absolute;
  bottom: 0;
  margin-bottom: 2.5em;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;
  a {
    line-height: 1.5;
  }
}
.portal-description .button-container {
  position: relative;
  margin-bottom: 0;
  margin-top: 32px;
  .loading-message-indicator {
    min-height: 42px;
  }
}

.portal-flex-container {
  .portal-description {
    padding-top: 1.5em;
    display: flex;
    flex-direction: column;
  }
}

.portal-description-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  font-size: @baseFontSize;
  line-height: 1.5;
  margin-bottom: 16px;
}

@media (min-width: 699px) {
  .portal-description-title {
    font-size: @subSmallHeadSize;
  }
}
.portal-registration-container, .portal-full-width-container {
  width: 93.5%;
  max-width: 100%;
}
.portal-full-width-container {
  font-size: @subheadSize;
  padding: 2em;
}

.portal-skyscraper-container {
  margin-top: 20px;
  .section-title {
    color: #FFF;
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
@media (min-width: 699px) {
  .portal-skyscraper-container {
    .section-title {
      font-size: 1.4em;
    }
  }
}
@media (min-width: 992px) {
  .portal-skyscraper-container {
    .bottom {
      flex-direction: row;
    }
  }
}
section.header {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid @muterColor;
  .header__div--title {
    margin-right: 16px;
    width: 60%;
    max-width: 100%;
  }
  .icon {
    padding: 12px;
    color: @mutedColor;
    background: @muterColor;
  }
  .btn {
    padding: 0 4px;
    margin-bottom: 8px;
  }
}
@media (min-width: 768px) {
  section.header {
    margin-top: 72px;
  }
}
@media (min-width: 992px) {
  section.header {
    margin-top: 16px;
  }
}

.report {
  position: relative;
  min-height: 25vh;
  .loader {
    position: absolute;
    z-index: 5;
    top: 32px;
    left: 32px;
  }
  .error-message {
    margin-top: 16px;
  }
  .report-legend {
    visibility: hidden;
    margin-top: 8px;
    font-size: 12px;
    &.active {
      visibility: visible;
    }
    .title {
      font-weight: 500;
    }
    .legend-list {
      display: flex;
      flex-direction: row;
      gap: 2px;
      justify-content: flex-start;
    }
    .soft-trigger-legend,
    .hard-trigger-legend,
    .in-progress-legend {
      display:inline-block;
      margin-left: 8px;
    }
    .soft-trigger-legend:before {
      content: "*";
      display: inline-block;
    }
    .hard-trigger-legend:before {
      content: "**";
      display: inline-block;
    }
    .in-progress-legend:before {
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      content: "\270f";
      margin-right: 4px;
      position: relative;
      top: 2px;
    }
    .no-contact-legend {
      display: inline-block;
      margin-left: 8px;
      color: @warningColor;
    }
  }
  .nav-arrow {
    position: absolute;
    z-index: 5;
    cursor: pointer;
    font-size: 14px;
    top: 10px;
    display: inline-block;
    width: 32px;
    height: 32px;
    background: #FFF;
    border-radius: 100%;
    padding-left: 10px;
    padding-top: 4px;
    color: @mutedColor;
    border: 1px solid @mutedColor;
    z-index: 100;
    &.start {
      margin-right: 8px;
      left: 40%;
    }
    &.end {
      padding-left: 12px;
      right: 16px;
    }
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  .table-container {
    position: relative;
  }
  .report-table {
    margin-top: 8px;
    TR:hover {
      background-color: lighten(@muterColor, 5%);
    }
  }
  @media (min-width: 1400px) {
    .report-table {
      TH:last-of-type {
        min-width: 200px;
        padding-right: 64px;
      }
    }
  }
  .container {
    padding-right: 0;
    padding-left: 0;
  }
  TH {
    background: @reportHeadRowBackgroundColor;
    font-weight: 600;
    color: @mutedColor;
    padding: 16px 16px 16px 24px;
    letter-spacing: 0.1rem;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  TH.title {
    text-transform: uppercase;
    width: 50%;
    padding: 16px;
    &:not(:first-of-type) {
      padding-left: 32px;
    }
  }
  .answer {
    border-radius: 50vmax;
    background: @muterColor;
    display: inline-block;
    padding: 8px 24px;
    min-width: 180px;
    text-align: center;
    &.warning {
      background-color: darken(@warningColor, 1%);
      color: #FFF
    }
    &.darker {
      background: lighten(@mutedColor, 5%);
      color: #FFF;
    }
    &.darkest {
      background: darken(@mutedColor, 12%);
      color: #FFF;
    }
    &.no-value {
      background: transparent;
    }
  }
  .domainText {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 16px;
    display: block;
  }
  .item,
  td.item {
    padding: 16px 12px 16px 12px;
    &.question {
      padding-left: 12px;
      padding-right: 32px;
    }
    line-height: 1.5;
    vertical-align: middle;
  }
  td.domain ~ td.item {
    padding-top: 32px;
  }
  .cell.active {
    display: table-cell !important;
  }
  .cell.inactive {
    display: none;
  }
  td.cell {
    position: relative;
    overflow: hidden;
    &:before {
      transform: translateX(1);
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: #FFF;
      opacity: 0.9;
    }
    &.active:before {
      -webkit-animation: popOut 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      -moz-animation: popOut 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      -ms-animation: popOut 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      -o-animation: popOut 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      animation: popOut 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      animation-fill-mode: forwards;
    }
    &.inactive:before {
      transform: translateX(1);
    }
  }
  tr:not(:last-of-type) {
    border-bottom: 1px solid @muterColor;
  }
}
@media (min-width: 768px) {
  .report {
    TH.title {
      width: 35%;
    }
    .nav-arrow {
      &.start {
        left: 30%;
      }
    }
  }
}
#postInterventionQuestionnaireLoc {
  height: 2px;
  overflow: hidden;
}
#postTxQuestionnaireContainer {
  position: relative;
  &.active {
    min-height: 250px;
  }
  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: lighten(@profileItemBgColor, 5%);
    opacity: 0.2;
    z-index: 50;
    .icon {
      position: absolute;
      z-index: 51;
      left: calc(50% - 2em);
      top: 25%
    }
  }
  .action-status {
    margin-top: 16px;
  }
  .footer-container {
    position: relative;
    #postTxResolutionContainer {
      position: absolute;
      top: 0;
      z-index: 10;
      min-height: 72px;
    }
  }
  #triggersSection {
    margin-bottom: 12px;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    .item {
      margin-bottom: 16px;
      .text {
        font-weight: 500;
      }
    }
    .list {
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2;
      max-width: 100%;
      padding: 0;
      margin-top: 8px;
      margin-right: 16px;
      align-items: flex-start;
      list-style-type: square;
      list-style-position: inside;
    }
  }
  #noTriggersSection {
    .item {
      margin-bottom: 8px;
      p {
        line-height: 1.45;
      }
    }
    margin-bottom: 4px;
    border-bottom: 1px solid darken(@muterColor, 10%);
  }
  #reportNoteSection {
    margin-bottom: 24px;
    font-style: italic;
    font-size: @mobileSize;
    color: darken(@mutedColor, 5%);
  }
  #questionsSection {
    position: relative;
    &.disabled:before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
      background: @profileItemBgColor;
      opacity: 0.3;
      z-index: 5;
      transition: opacity 350ms ease 50ms;
    }
  }
  #postTxResolutionContainer {
    font-weight: 500;
  }
  .question {
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;
    &:has(.title:empty:not(.placeholder)) {
      margin-top: -24px;
      margin-bottom: 0;
    }
    &.partOf {
      margin-top: -12px;
      margin-bottom: 24px;
      input {
        order: 1
      }
      .text {
        order: 2
      }
    }
    .title {
      display: inline-block;
      margin-right: 8px;
      min-width: 132px;
      color: @titleTextColor;
      font-weight: 500;
      &.placeholder {
        min-width: 136px;
      }
      &.boolean {
        color: @baseColor;
      }
    }
    .flex {
      input {
        margin: 0;
      }
    }
  }
  .choices {
    margin-top: 8px;
    margin-bottom: 8px;
    .choice {
      margin-bottom: 12px;
      display: flex;
      align-items: flex-start;
      .text {
        margin-left: 8px;
      }
    }
  }
  .question:first-of-type {
    .choices {
      margin-top: 0;
    }
  }
  select,
  .data-datepicker {
    display: inline-block;
    width: 250px;
  }
  .data-datepicker {
    background: #FFF;
    &.disabled {
      background: @muterColor;
    }
  }
  .bool {
    float:left;
    margin-right: 8px;
  }
  .addl-text {
    width: 80%;
    max-width: 100%;
    margin-left: 16px;
    margin-top: 12px;
    height: 72px;
  }
  .error-message {
    margin-top: 24px;
    .icon {
      margin-right: 4px;
    }
  }
  #postTxSubmitContainer {
    margin-bottom: 16px;
  }
}
@media (min-width: 699px) {
  #postTxQuestionnaireContainer {
    #triggersSection {
      .list {
        width: 100%;
        display: grid;
        align-content: space-evenly;
        grid-template-columns: repeat(2, 180px);
        gap: 8px;
      }
    }
  }
}
@media (min-width: 992px) {
  #postTxQuestionnaireContainer {
    #triggersSection {
      .list {
        grid-template-columns: repeat(3, 180px);
      }
    }
  }
}

#emproModal,
#emproOptOutModal {
  overflow-y: auto;
  .modal-dialog {
    width: 100%;
    padding: 8px;
    margin: 16px auto;
  }
  .modal-body {
    padding: 16px 32px 0;
  }
  .modal-content {
    border-radius: 10px;
    position: relative;
    .close {
      position: absolute;
      right: 24px;
      top: 16px;
      font-size: 2.8rem;
      display: block;
      font-weight: 400;
      color: @mutedColor;
      opacity: 1;
      z-index: 10;
    }
    p {
      margin: 0 0 12px;
      line-height: 1.535;
    }
    .item {
      .ck-input {
        margin: 4px 2px 8px 0;
      }
    }
    .continue-container {
      margin-top: 8px;
    }
    .saving-indicator-container {
      margin-top: 16px;
    }
    .no-contact-list-wrapper {
      margin-top: 16px;
      margin-bottom: 16px;
      &:empty {
        margin-top: 0;
        margin-bottom: 0;
      }
      color: @warningColor;
    }
    .error-message {
      white-space: pre;
      line-height: 1.55;
    }
    .save-success-indicator-container {
      margin-top: 16px;
    }
  }
  .modal-footer {
    background: @bodyBgColor;
    color: @baseColor;
    text-align: left;
    padding: 16px 32px 24px;
    border-top: 0;
    border-radius: 10px;
    &.hard-trigger,
    &.soft-trigger {
      display: none;
    }
    &.hard-trigger.active,
    &.soft-trigger.active {
      display: block;
    }
  }
  .title {
    margin-bottom: 16px;
    margin-top: 8px;
    width: 100%;
    line-height: 1.4;
    padding-right: 32px;
  }
  .header-section {
    padding-bottom: 0;
    border-bottom: 1px solid @HRColor;
  }
  .items-section {
    position: relative;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-evenly;
    &.hard-trigger,
    &.soft-trigger {
      display: none;
    }
    &.active {
      display: flex;
    }
    &.loading {
      &:before {
        content: ' ';
        display: block;
        font-size: 8px;
        width: 8px;
        height: 8px;
        -webkit-animation: spinner 1500ms infinite linear;
        -moz-animation: spinner 1500ms infinite linear;
        -ms-animation: spinner 1500ms infinite linear;
        -o-animation: spinner 1500ms infinite linear;
        animation: spinner 1500ms infinite linear;
        -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
        box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
        position: absolute;
        left: calc(100% / 2 - 8px);
        top: calc(100% / 2 - 8px);
        z-index: 999;
        border-radius: 4px;
      }
      &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #FFF;
        opacity: 0.6;
      }
    }
    .item {
      padding: 8px 8px 24px;
      width: 100%;
      border-bottom: 1px solid @HRColor;
      &:last-of-type {
        border-bottom: 0;
      }
      ul {
        padding-left: 20px;
        //margin-bottom: 20px;
      }
      li:not(:last-of-type) {
        margin-bottom: 8px;
      }
      li:last-of-type {
        margin-bottom: 16px;
      }
    }
    .buttons-container {
      margin-top: 24px;
    }
    a, button {
      background-color: @emproBtnPrimaryColor;
      color: #FFF;
      display: block;
      width: 300px;
      max-width: 100%;
      white-space: normal;
      margin: 8px auto;
    }
  }
}

@media (min-width: 768px) {
  #emproModal {
    .items-section {
      .item {
        padding: 8px 24px 0;
        width: 33.33333333%;
        min-height: 360px;
        flex-wrap: nowrap;
        flex: 1;
        border-bottom: 0;
        &:first-of-type {
          padding-left: 0;
          flex: 1.35;
        }
        &:not(:last-of-type) {
          border-right: 1px solid @HRColor;
        }
      }
      a, button {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
@media (min-width: 992px) {
  #emproOptOutModal {
    .modal-dialog {
      max-width: 960px;
      margin: auto;
    }
  }
  #emproModal {
    .modal-dialog {
      max-width: 1160px;
      margin: auto;
    }
  }
}

#emproOptOutModal {
  .header-section {
    padding-bottom: 8px;
    margin-bottom: 24px;
    .subtitle {
      font-size: 1.3em;
      margin-bottom: 0;
    }
  }
  .title {
    margin-top: 8px;
    padding-bottom: 0;
    margin-bottom: 4px;
    font-size: 1.7em;
  }
  .items-section {
    .item {
      padding: 0 24px;
      &.wrap {
        display: flex;
        gap: 24px;
      }
    }
  }
  .items-wrapper {
    border: 1px solid @HRColor;
    padding: 16px 24px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .items {
    margin-left: 16px;
    .item {
      display: flex;
      gap: 8px;
      input:hover {
        cursor: pointer;
      }
      &:not(:last-of-type) {
        margin-bottom:8px;
      }
      .ck-display:hover {
        cursor: pointer;
        color: @emproBtnPrimaryColor;
      }
    }
  }
  .modal-footer {
    text-align: center;
    .btn {
      font-size: 0.9em;
      padding: 1em;
      min-width: 112px;
    }
    .btn-empro-primary {
      background-color: @emproBtnPrimaryColor;
      color: #FFF;
    }
    .btn-default {
      border: 1px solid @emproBtnPrimaryColor;
      font-size: 0.88em;
      padding: 0.88em;
    }
  }
  
}

.loading-message-indicator {
  z-index: 10;
  display: none;
}
.img-with-text {
  position: relative;
  &.flush-with-footer {
    margin-bottom: -30px;
  }
  &.full-width {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.separator-horizontal, .separator-vertical {
  position: relative;
  text-transform: uppercase;
  text-align: center;
  color: #000;
  font-weight: bold;
  display: block;
  padding: 0;
  &:before {
    content: '';
    display: block;
    position: absolute;
    border-style: solid;
    border-color: #C4C6C7;
  }
  > span {
    background: #FFF;
    display: inline-block;
  }
}
.separator-vertical {
  height: 100%;
  margin: 0 2em;
  &:before {
    left: 50%;
    margin-left: -1px;
    height: 100%;
    border-width: 0 0 0 1px;
  }
  > span {
    height: 3em;
    line-height: 3em;
    width: 4em;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.5em;
    margin-left: -2em;
  }
}
.tnth-form .form-group > label {
  color: #777;
}
.form-with-floats {
  .form-group > label {
    color: #aaa;
    opacity: 0;
    &.after-load {
      transition: all 0.1s linear;
      opacity: 1;
    }
  }
  .float-input-label {
    position:relative;
    margin-top: 30px;
    > label {
      position:absolute;
      top:7px;
      left:13px;
      opacity:0;
      &.after-load {
        transition: all 0.1s linear;
        opacity: 1;
      }
      &.show {
        top:-24px;
        left: 0;
      }
    }

  }
}
.first-name-container .float-input-label,
.last-name-container .float-input-label {
  margin-bottom: 0;
}
.first-name-container,
.last-name-container {
  margin-bottom: 0.5em;
}
.profile-item-container {
  background-color: @profileItemBgColor;
  background-image: -moz-linear-gradient(to top, @profileItemBgColor 0%, #f5f5f5 100%);
  background-image: -webkit-linear-gradient(to top, @profileItemBgColor 0%, #f5f5f5 100%);
  background-image: linear-gradient(to top, @profileItemBgColor 0%, #f5f5f5 100%);
  padding: 2em 1em 2.5em 1em;
  margin: 0.5em auto;
  border: 2px solid #bcc2a6;
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 100%;
  .body-content {
    width: 85%;
    max-width: 100%;
    line-height: 1.5;
  }
 }

#profileProceduresWrapper.profile-item-container {
    border: 1px solid @HRColor;
}

#profileProceduresWrapper[data-top-org-name='IRONMAN'] {
    display: none;
}
#profileProceduresWrapper{
  &[data-user-view = 'true'] {
    .profile-item-edit-btn {
      display: none;
    }
  }
  #procDay {
    width: 100px;
  }
  #procYear {
    width: 155px;
  }
}

.core-data-procedure {
  .profile-item-container {
    background: none;
    border: 0 !important;
    padding: 0;
  }
  .profile-item-edit-btn {
    display: none;
  }
  #profileProcedureContainer {
    border-top: 1px solid #e8e7e7;
    border-bottom: 1px solid #e8e7e7;
    padding-top: 2em;
  }
}

#profileProcedureContainer {
  table {
    border: 0;
  }
  .confirm-delete,
  .data-delete {
    font-size: 0.85em;
    padding: 0.5em 0.8em;
    color: #777;
    border: 1px solid @btnDefaultBorderColor;
    position: relative;
  }
}
#userProcedures {
  td {
    padding-bottom: 0.2em;
  }
  .list-cell, .descriptionCell {
    padding-top: 0.2em;
  }
}
#tnthproc {
  width: 100%;
  max-width: 100%;
}
#tnthproc-submit {
  height: 2.5em;
  padding: 0.5em 0.9em;
}
.profile-item-title {
  color: @sectionTitleColor;
  margin-bottom: 2em;
  letter-spacing: 1px;
}
.detail-title {
  margin-bottom: 0;
}
#mainDiv.profile .profile-item-container .profile-item-title {
  max-width: 150px;
}
.content-container {
  position: relative;
}
#mainDiv.profile .profile-item-container .edit-container {
  display: none;
  max-width: 100%;
}
#mainDiv.profile .profile-item-container.edit .edit-container {
  display: inline-block;
}
.profile-item-container .view-container {
  display: none;
  max-width: 100%;
  word-break: break-word;
  padding-bottom: 1em;
}

#mainDiv.profile {
  .profile-item-container.editable {
    .profile-item-edit-btn {
      display: block;
    }
  }
  .section-loader {
    position: absolute;
    top: 2.5em;
    right: 2.5em;
    z-index: 11;
    letter-spacing: .05rem;
    font-size: .95em;
    opacity: .8;
    min-height: 4em;
  }
  .profile-item-edit-btn {
    display: none;
    position: absolute;
    top: 2.5em;
    right: 3em;
    margin-right: 1em;
    z-index: 10;
    height: 2.5em;
    width: auto;
    outline: 0;
    white-space: nowrap;
    padding: 0 0.7em;
    color: #FFF;
    background: @btnColor;
    border-color: @btnColor;
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0;
    -webkit-transition: opacity 550ms ease;
    -o-transition: opacity 550ms ease;
    transition: opacity 550ms ease;
  }
  .profile-item-edit-btn.active {
    opacity: 1;
  }
  .profile-item-edit-btn:hover,
  .profile-item-edit-btn:active,
  .profile-item-edit-btn:focus {
    color: #FFF;
    background-color: @linkHover;
  }
  .profile-item-container .edit-container {
    display: none;
    max-width: 100%;
  }
  .profile-item-container.edit .edit-container {
    display: inline-block;
  }
  .profile-item-container .view-container {
    display: block;
    position: relative;
    max-width: calc(~"100% - 70px");
    line-height: 1.65;
  }
  .profile-item-container .view-container td:first-of-type {
    display: inline-block;
    vertical-align: top;
  }
  .profile-item-container .view-container td {
    padding: 0.6em 1.5em 0.6em 0;
  }
  .profile-item-container.edit .view-container {
    display: none;
  }
  .profile-item-loader {
    position: absolute;
    top: 1%;
    left: 1%;
    width: 98%;
    height: 98%;
    padding: 8%;
    background-color: #FFF;
    opacity: 0.3;
    z-index: 21;
    display: none;
  }
}

.profile-item-title {
  color: #5b5e61;
  margin-bottom: 16px;
  margin-top: 12px;
  letter-spacing: 1px;
}
.profile-name-label, .profile-birthdate-label {
  margin-bottom: -4px;
}
/* profile send invite/reminder email containing element */
#profileregistrationEmailBtnMsgWrapper {
  position: relative;
}

#profileregistrationEmailLoadingIndicator {
  position: absolute;
  top: 0;
  left: 0;
  background: @profileItemBgColor;
  min-height: 80px;
  width: 100%;
}
#profileEmailMessage {
  margin-top: 1em;
}
#patientQContainer {
  width: 100%;
  max-width: 100%;
}
#resetPasswordContainer {
  flex-grow: 0.3;
}
#resetPasswordGroup {
  .loading-indicator {
    margin-top: 16px;
  }
}
#passwordResetMessage {
  width: 300px;
  max-width: 100%;
  margin-top: 1em;
}
#btnPasswordResetEmail,
#registrationEmailContainer #btnProfileSendEmail,
.sm-btn,
.btn-send-email {
  font-size: @smallBtnSize;
  height: 2.9em;
  padding: 0 1em;
}
#profileassessmentSendEmailContainer {
  display: none;
}

.communication-title {
  font-weight: 500;
  letter-spacing: 0.02rem;
  &.singleton {
    margin-bottom: -24px;
  }
}

.communication-prompt {
  margin-bottom: 1em;
}
.communication-detail-button {
  margin-right: 0.5em;
}
#commDetailTable {
  .label-cell {
    padding: 0.5em 0.5em 0.5em 0
  }
  .content {
    padding: 0.5em;
    background: #f7f9fb;
    margin: 0.5em;
  }
  .btn {
    font-size: 0.9em;
    display: inline-block;
    color: #FFF;
    background-color: #7C959E;
    border-color: #7C959E;
    border-radius: 0;
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.42857143;
    font-weight: 400;
    padding: 0.6em;
    text-decoration: none;
  }
}
#profileForm .communications-container .flex-item {
    flex: 0.3 350px;
}
.profile-name-label, .profile-birthdate-label {
  margin-bottom: -4px !important;
}
#treatingClinicianContainer {
  .select-list {
    margin-top: 16px;
    margin-bottom: 24px;
  }
  #clinicianSelector {
    min-width: 172px;
    max-width: 488px;
  }
}

.deceased-date-container {
  margin-top: -0.75em;
}

#createUserLink {
  max-width: 100%;
  min-height: 3em;
  white-space: pre-wrap;
  display: inline-block;
  padding: 15px 12px;
  min-width: 300px;
  text-align: center;
  color: #FFF;
  background: @btnColor;
  border-color: @btnColor;
  align-self: flex-start;
   &:hover {
    color: @featureBtnHoverColor;
    background: @linkHover;
  }
}

.profile-create-item-container {
  border-radius: 8px;
  margin: 0.5em auto;
  border: 1px solid @sectionBorderColor;
  padding: 1.5em;

}

#profileForm {
  #firstname,
  #lastname {
    width: 300px;
    max-width: 100%;
  }
  #errorbirthday {
    display: none;
  }
}
#userEthnicity label {
  margin-bottom: 0
}

#userRace  {
  label {
    margin-bottom: 0
  }
  .checkbox {
  margin-left: 0.1em;
  margin-right: 0.1em;
  }
}

#userIndigenousStatusContainer {
  .checkbox label{
    margin-bottom: 4px;
  }
  .radio label{
    margin-bottom: 4px;
    margin-left: 4px;
  }
  input, label {
    margin-left: 6px;
  }
}

#fillOrgs *,
.noOrg-container * {
 font-size: @mobileOrgSize;
}
#fillOrgs legend {
  color: @legendColor;
  margin: 4px 0 4px 0;
}
#fillOrgs .divider {
  display:block;
  height:0.25em;
}

.noOrg-container{
  margin-top: 0.2em;
  label {
    font-weight: normal;
  }
  input[type="radio"] {
    left: 2px;
    top: 2px;
  }
  span {
    margin-left:5px;
    position: relative;
    top: 1px;
  }
}
input.clinic {
  margin-top: 6px;
}
#fillOrgs label.org-label input[type="radio"] {
  position: relative;
  right: 5px !important;
}
#createProfileForm  {
  .tnth-headline {
    padding-bottom: 8px;
    border-bottom: 1px solid @HRColor;
    margin-bottom: 4px;
  }
  label {
    font-weight: 500;
    letter-spacing: 0.02rem;
  }
  .optional-display-text {
    font-weight: normal;
  }
  #fillOrgs {
    label {
      font-weight: normal;
    }
  }
  .profile-item-edit-btn {
    display: none;
  }
  .title {
    margin-top: 5%;
  }
  .name-section {
    padding-bottom: 0;
  }
  #phone, #email, #altPhone  {
    width: 300px;
    max-width: 100%;
  }
  #bdGroup {
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0.1em;
    padding-bottom: 0;
    .flex {
      flex-wrap: wrap;
    }
  }
  #emailGroup {
    margin-top: 1em;
  }
  #phoneGroup {
    margin-top: 1.25em;
  }
  #altPhoneGroup {
    margin-top: 1.25em;

  }
  #studyIdContainer {
   margin-top: 1.5em;
  }
  .study-id-label {
    margin-top: 0.5em;
  }
  #profileStudyIDContainer {
    margin-top: 1.6em;
    margin-bottom: 1em;
  }

  #phone, #email, #altPhone, #studyId,
  #profileStudyIDContainer input  {
    width: 100%;
    max-width: 100%;
  }
  #userOrgs .smaller-text {
    display: none;
  }
  #noEmailContainer {
    margin-top: 0.3em;
  }
  #noEmail {
    position:relative;
    top:-1px;
    left: 1px;
  }
  .btn-tnth-back {
    background: none;
  }
  #updateProfile {
    font-size: @smallBtnSize;
  }
  label.clinics-section-label {
    margin-bottom: 0;
  }

  #profileProceduresWrapper {
    padding: 1em 1.5em;
    background: none;
    margin-top: 1em;
    margin-bottom: 2em;
    .profile-item-title {
      font-weight: 500;
    }
    .optional-display-text {
      font-weight: normal;
    }
    label {
      font-weight: normal;
    }
  }
  .profile-item-title {
    color: #777777;
    font-weight: normal;
    letter-spacing: 0;
    font-size: @baseFontSize;
  }

  #pastTreatmentsContainer {
    margin-top: 1em;
    margin-bottom: 2em;
    td {
      padding: 0.3em 0.5em;
    }
  }
  .save-button-container {
    padding: 1.5em 0 0;
  }
  #tnthproc-submit {
    height: auto;
  }
}
#rolesContainer {
  margin: 0.5em 0 2.25em;
  .item {
    margin-bottom: 4px;
  }
}
#rolesGroup {
  position: relative;
  &:before {
    z-index: -1;
  }
  &.loading {
    &:before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background:transparent;
      z-index: 5;
    }
  }
}
.create-account-container {
  position: relative;
  padding: 2em;
  width: 100%;
  max-width: 100%;
  border: 0;
  background: @wellColor;
  box-shadow: 1px 2px 4px darken(@wellColor, 3%);
  overflow: hidden;
  #erroremail {
    color: @errorMessageColor;
  }
}
#accountCreationContentContainer {
  position: relative;
}
#accountCreationContentContainer[data-account='patient'] #bdGroup {
  min-height: 114px;
}
#accountCreationContentContainer[data-account='staff'] {
  #bdGroup {
    margin-top: 1em;
    margin-bottom: 0.25em;
  }
  #emalGroup {
    margin-top: 0.35em;
  }
  #altPhoneGroup {
    margin-top: 0.75em;
  }
  #phoneGroup {
    margin-top: 1.35em;
  }
}
#emailInfoText {
  display: none;
}
#profileSendEmailContainer,
.profile-email-container {
  padding-bottom: 5%;
  background-color: #f9f9f9;
  background-color: rgba(204, 204, 204, 0.3);
  border-radius: 6px;
  width: 400px;
  max-width: 100%;
}

.left-indent-bottom {
  margin-left: 3.2em;
}

.left-indent-top {
  margin-left: 2.5em;
}


.send-email-info {
  font-size: @smallText;
  margin-top: 1em;
  margin-left:0.5em;
  width: 250px;
  max-width: 100%;
  word-wrap: break-word;
}
#sendRegistrationEmailForm {
  label {
    font-weight: normal;
  }
  .buttons-container {
    position: relative;
    .loading-indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 250px;
      max-width: 100%;
      min-height: 4em;
      display: none;
      z-index: 5;
      background: #F0F0F0;
    }
  }
  #profileEmailErrorMessage {
    margin: 1em 0;
  }
}

#profileStudyIDContainer {
  input {
    width: 300px;
    max-width: 100%;
  }
}

#TwoFAForm {
  .form-group {
      margin-bottom: 8px;
   }
   input[type=text] {
      width: 200px;
   }
   .remember-me-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 8px;
        align-items: center;
        margin-bottom: 32px;
   }
  .buttons-container {
    position: relative;
    min-height: 56px;
    .loader {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: #FFF;
      z-index: 10;
      i {
        position: absolute;
        left: 0;
        top: 8px;
      }
      display: none;
    }
    .btn {
      min-width: 200px;
    }
    &.loading {
      .loader {
        display: block;
      }
      .btn {
        visibility: hidden;
      }
    }
  }
}

#userSessionReportDetailTable {
  margin-left: -1%;
  th, td {
    padding: 8px;
  }
  th {
    position: sticky;
    top: 0;
    background-color: #FFF;
  }
}

#userSessionReportDetailHeader a[href] {
  background-image: -webkit-linear-gradient(top, #fff, #eee);
  background-image: -moz-linear-gradient(top, #fff, #eee);
  background-image: -ms-linear-gradient(top, #fff, #eee);
  background-image: -o-linear-gradient(top, #fff, #eee);
  background-image: linear-gradient(to bottom, #fff, #eee);
  -webkit-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.4);
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  color: #606a73;
  padding: 8px 12px;
  margin-top: 0.5em;
}
#userSessionReportDetailHeader a[href]:hover {
  color: @linkHover;
}
#userSessionReportDetailTable caption {
  padding: 0 8px;
  font-weight: 500;
}
#userSessionsList label {
  margin-bottom: 6px;
}
#userSessionListTable TR:hover {
  cursor: pointer;
}
#userAssessementReport table {
  word-break:break-all;
}
.profile-header {
  display: inline-block;
}
.profile-section {
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0;
}
.orgLinks {
  margin-top: 4px;
}

.error-message, .report-error-message {
  text-align: left;
  color: @errorMessageColor;
}

.report-error-message {
  padding: 0.5em;
  background-color: #f5f5f5;
  margin-left: 8px;
  display: none;
}

#contactForm .post-contact-response {
  padding: 0;
  margin: 1em 0;
}

/*.loading-indicator {*/
#loadingIndicator,
.loading-indicator-placeholder {
  /*display: none;*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2em;
  width: 2em;
  overflow: show;
  text-align: center;
  z-index: 99999;
  color: #444;
  margin: auto;
  background: transparent;
}
body.vis-on-callback { /* allow page-specific presentation of content after loading */
  #loadingIndicator,
  .loading-indicator-placeholder {
    visibility: visible !important;
    display: block !important;
  }
  #mainHolder,
  #mainNav {
    opacity: 0 !important;
  }
}
#loadingIndicator:before,
.loading-indicator-placeholder:before {
  content: ' ';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: @wellColor;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

#loadingIndicator i,
.loading-indicator-placeholder i {
  font-size: @baseFontSize;
}

/* :not(:required) hides these rules from IE9 and below */
#loadingIndicator:not(:required),
.loading-indicator-placeholder:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

#loadingIndicator:not(:required):after,
.loading-indicator-placeholder:not(:required):after {
  content: ' ';
  display: block;
  font-size: 12px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes popOut {
  0% {
    -webkit-transform: translateX(1);
    -moz-transform: translateX(1);
    -ms-transform: translateX(1);
    -o-transform: translateX(1);
    transform: translateX(1);
  }
  100% {
    -webkit-transform: translateX(-500%);
    -moz-transform: translateX(-500%);
    -ms-transform: translateX(-500%);
    -o-transform: translateX(-500%);
    transform: translateX(-500%);
  }
}
@-moz-keyframes popOut {
  0% {
    -webkit-transform: translateX(1);
    -moz-transform: translateX(1);
    -ms-transform: translateX(1);
    -o-transform: translateX(1);
    transform: translateX(1);
  }
  100% {
    -webkit-transform: translateX(-500%);
    -moz-transform: translateX(-500%);
    -ms-transform: translateX(-500%);
    -o-transform: translateX(-500%);
    transform: translateX(-500%);
  }
}
@-o-keyframes popOut {
  0% {
    -webkit-transform: translateX(1);
    -moz-transform: translateX(1);
    -ms-transform: translateX(1);
    -o-transform: translateX(1);
    transform: translateX(1);
  }
  100% {
    -webkit-transform: translateX(-500%);
    -moz-transform: translateX(-500%);
    -ms-transform: translateX(-500%);
    -o-transform: translateX(-500%);
    transform: translateX(-500%);
  }
}
@keyframes popOut {
  0% {
    -webkit-transform: translateX(1);
    -moz-transform: translateX(1);
    -ms-transform: translateX(1);
    -o-transform: translateX(1);
    transform: translateX(1);
  }
  100% {
    -webkit-transform: translateX(-500%);
    -moz-transform: translateX(-500%);
    -ms-transform: translateX(-500%);
    -o-transform: translateX(-500%);
    transform: translateX(-500%);
  }
}
.custom-container {
  display: block;
  margin-bottom: 1em;
  padding: 0.1em 0 1.5em 0;
  .profile-item-container {
    padding: 1.5em 2em 2em 2em;
  }
  h4 {
    margin-bottom: 1em;
  }
  .profile-email-container {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    margin: 0;
  }
  #profileEmailSelect,
  .email-selector {
      width: 280px;
      max-width: 100%;
      & option.inactive {
        display: none;
      }
  }
  #btnProfileSendEmail, .custom-btn,
  .btn-send-email{
      width: 280px;
      max-width: 100%;
      font-size: @smallBtnSize;
  }
  #btnProfileSendEmail,
  .btn-send-email {
    margin-top: 0.5em;
  }
  .custom-btn {
    margin-bottom: 0.9em;
  }
  .custom-container-item, .custom-container-item-right {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .enter-manual-container {
    flex-grow: 1.5;
  }
}
/*
 * hide duplicate feature(s) that is already present in custom detail
 */
 #mainDiv.profile {
    #profileSendEmailContainer,
    .registration-email-prompt,
    #sendEmailLabel,
    #loginAsPatient,
    #navMenuXs {
      display: none
    }
 }
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  div.flex-item {
    padding: 4px 12px 4px 0;
    max-width: 100%;
  }
}
.flex-align-center {
  align-items: center;
}
.flex-row-gap-1x {
  row-gap: 8px
}
.flex-column-gap-1x {
  column-gap: 8px
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
}
.flex-in-between {
  display: flex;
  justify-content: space-between;
}
.btn-group>.btn:first-child {
  font-size: @mediumText * 0.89 !important;
}
.pagination,
.pagination button {
  font-size: @mediumText !important;
}
.pagination-detail {
  font-size: @mediumText !important;
  button.dropdown-toggle {
    padding: 0.4em 0.6em;
  }
  .btn-group>.btn:first-child {
    margin-left: 8px;
    margin-right: 8px;
  }
}
.pagination {

  >.active>a {
    background-color: @btnColor;
    border-color: @btnColor;
  }
  >.active>a:focus {
    background-color: @btnColor;
    border-color: @btnColor;
  }
  >.active>a:hover{
    background-color: @linkHover;
    border-color: @linkHover;
  }
  >.active>span{
    background-color: @btnColor;
    border-color: @btnColor;
  }
  >.active>span:focus{
    background-color: @btnColor;
    border-color: @btnColor;
  }
  >.active>span:hover {
    background-color: @linkHover;
    border-color: @linkHover;
  }
}

.pagination {
  >li>a {
    color: @baseColor;
  }
  >li>span {
    color: @btnColor;
  }
}

/**override bootstrap style**/
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
  margin-top: 5px;
}
.checkbox input[type=checkbox] {
    top: 0;
}
.radio input[type=radio] {
  position: relative;
  left: 3px;
  margin-right: 9px;
}
.radio-inline input[type=radio] {
  margin-left: -18px;
}
.modal-content {
  border-radius: 0;
}
.modal-header,
.modal-footer {
  background-color: @modalBackgroundColor;
  color: #FFF;
  letter-spacing: 1px;
}
.modal-body {
  font-size: @baseFontSize;
  padding: 2em 2.5em;
}
.modal-title {
  font-weight: normal;
  font-size: @baseFontSize;
}
.modal-header,
.modal-footer {
  font-size: @baseFontSize;
}
.modal-footer {
  min-height: 4em;
}
.modal-header,
.modal-footer {
  padding: 0.7em;
}
.modal-header button,
.modal-header button * {
  color: #FFF;
  font-size: @baseMobileFontSize;
}
.modal label {
  font-size: @baseMobileFontSize;
}

.modal button,
.modal a.btn {
  font-size: @modalButtonSize;
}
.modal-backdrop {
  opacity: 0.6
}
#modalCookieEnableWarning  {
  .modal-dialog {
    width: 600px;
    max-width: 100%;
    margin: auto;
  }
  .modal-body__privacy {
    display: none;
  }
}
#urlAuthenticatedModal {
  .btn {
    margin-bottom: 12px;
  }
}
.cookie-monster-modal-backdrop-cover {
  position: fixed;
  background: #FFF;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}
.cookie-disabled {
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: block;
    background: #FFF;
    opacity: 0.6;
    z-index: 2;
  }
}
.cookie-disabled:after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: block;
  background: #FFF;
  opacity: 0.6;
  z-index: 2;
}
.cookie-disabled:hover {
  cursor: auto;
}

#manualEntryModal {
  .modal-dialog {
    width: auto;
  }
  input[type='radio'] {
    margin-top: 4px;
    margin-left: -20px;
  }
  .modal-title {
    margin: 0.2em;
  }
  #manualEntryWarningMessageContainer {
    .indent {
      padding: 0.1em 1.5em;
    }
  }
  .loading-message-indicator {
    display: block;
  }
}
#enterManualInfoContainer {
  margin-bottom: 1em;
  .flex {
    flex-wrap: wrap
  }
}
#manualEntryVisitContainer,
#manualEntryCompletionDateContainer {
  margin-top: 1em;
  label {
    position: relative;
    margin-bottom: 2px;
    padding: 0;
    font-size: @mobileSize;
    font-weight: 400;
  }
}
#manualEntryVisitContainer {
  label {
    margin-bottom: 6px;
  }
}
#manualEntryCompletionDateContainer {
  border: 1px solid @muterColor;
  padding: 8px 16px;
  select {
    min-width: 178px;
  }
  .form-group {
    margin-bottom: 0;
  }
  .help-block {
    font-size: 1em;
  }
}
#manualEntryVisitContainer {
  margin-top: 8px;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  select {
    width: 200px;
    max-width: 100%;
  }
  .info {
    margin-left: 0;
    margin-top: 24px;
    font-size: @mobileSize;
  }
}
@media (min-width: 567px) {
  #manualEntryVisitContainer {
    .info {
      margin-left: 16px;
    }
  }
}
#manualEntryWarningMessageContainer {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 0.95em;
  &:empty {
    margin-top: 0;
    margin-bottom: 0;
  }
}
#emailBodyModal .body-content {
  overflow: hidden;
  font-size: @mediumText;
}
#profileEmailLogTable {
  .message-subject {
    position: relative;
  }
  .message-loader {
    position: absolute;
    z-index: 1;
  }
}
.hide {
  display: none;
}
sub.pointer {
  cursor:pointer;
}
sub.pointer:hover {
  color: @linkHover;
}

.report-custom-header {
  padding: 0.3em 0.5em;
  border-radius: 4px;
  border-left: 2px solid #f5f5f5;
  border-bottom: 2px solid #f5f5f5;
  width: 100%;
  max-width: 100%;
  margin: auto;
  height: 86px;
}

.report-custom-header .left {
float:left;
}

.report-custom-header .right {
float: right;
position: relative;
z-index: 88;
color: #777
}

.tooltip > .tooltip-inner{
    background-color: @toolTipColor;
    border: 1px solid @toolTipColor;
    padding: 0.3em;
    width: 170%;
    font-size: @smallerText;
}
.tooltip.top .tooltip-arrow {
    border-top-color: @toolTipColor;
}

.tooltip.right .tooltip-arrow {
    border-right-color: @toolTipColor;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: @toolTipColor;
}

.tooltip.left .tooltip-arrow {
    border-left-color: @toolTipColor;
}

div.biopsy-option:last-of-type{
  margin-top: 1px;
}
#biopsyDate {
  width: 150px;
  max-width: 100%;
  display: inline-block;
}
#biopsyDateContainer {
  min-height: 4.5em;
}

#consentContainer {
  .fade {
    transition: opacity .45s ease-out;
  }
  .consent {
  display: none;
  padding: 1.15em 1.3em;
  color: #57675B;
  width: 100%;
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  }
  label {
  font-weight: normal;
  margin-left: 6px;
  }
  a {
  color:#696f69;
  margin-left: 1.3em;
  }
  a:hover {
  color: @linkHover;
  }
}
.consent-checkbox {
  margin-left:-2px;
  margin-right: 8px;
  position:relative;
  top:-2px;
}

.withdrawn-label [data-eproms] {
  display: inline;
}
.withdrawn-label [data-truenth] {
  display: none;
}
.truenth-tou-table-text,
.agreement {
  display: none;
}
#consentDateEditContainer {
  margin-top: 2.25em;
  margin-bottom: 2em;
  label:first-of-type {
    margin-bottom: -0.1em;
  }
}
#consentDateModal {
    .form-control {
      margin: 0.5em 0;
    }
    .consent-hour,
    .consent-minute,
    .consent-second {
      width: 60px;
    }
}
#profileConsentList,
#profileConsentHistory {
  max-width: 100%;
  padding: 0.1em;
  overflow-x: auto;
  a {
    text-decoration:underline;
  }
  a:hover {
    color: @linkHover;
  }
  .edit-icon {
    cursor:pointer;
    color: #000;
  }
  .consentlist-header {
    background-color: @rowBackgroundColor;
    color: #FFF;
    font-weight: normal;
    padding-left: 0.6em;
    padding-right: 0.6em;
  }
  .consentlist-cell{
    background-color: #fbf9f9;
    vertical-align: top;
  }
}
#consentHistoryTable {
  tr.history td {
    background-color: #ececec;
  }
}

@media (min-width: 1200px) {
  #consentHistoryTable {
    td {
      min-width: 150px;
    }
  }
}

.button--LR,
.button--LR.data-show,
#profileConsentList .button--LR{
   opacity: 0;
   margin: 2em 0;
}
#profileConsentList .button--LR {
  margin: 0 0.5em;
  display: inline-block;
}
.button--LR.data-show,
#profileConsentList .button--LR[data-show = 'true'] {
    opacity: 1;
}
#profileConsentList .button--LR[data-show = 'false'] {
    opacity: 0;
}
#profileConsentList {
  .button--LR,
  .button--LR.show {
    margin: 0 0.5em;
    display: inline-block !important;
  }
}
#profileConsentList,
#profileConsentHistory {
  .truenth-consent-status-header,
  .truenth-consent-date-header,
  .truenth-tou-table-text {
    display: none;
  }
}
#consentHistoryWrapper {
  max-height: 500px;
  overflow-y: auto;
}
#consentListLoad {
  margin-top: 1em;
}
.consent-date-modal.fade {
    -webkit-transition: opacity .7s ease-out !important;
    -o-transition: opacity .7s ease-out !important;
    transition: opacity .7s ease-out !important;
}

.btn-delete-consent, .btn-add-consent {
  color: @rowBackgroundColor;
  font-size: @btnSize;
  font-weight: bold;
}

#profileSiteIDContainer, #mainDiv.profile .site-id-view {
  display: none;
}
.challengeForm__h4 {
  border-bottom: 1px solid @HRColor;
  padding-bottom: 0.5em;
  margin-top: 1.5em;
  margin-bottom: 1em;
}
#challengeForm {
  .label {
    color: #777;
    font-size: 0.9em;
    margin-left: -8px;
    position: relative;
    top:-3px;
  }
  .field {
    margin-top: 1.2em;
  }
  .hr {
    margin-top: -8px;
  }
  .title {
    color: #6b7571;
    font-weight: 500;
  }
  .error-message {
    margin-left: 0;
    display: none;
  }
  .form___submit_container {
    margin-top: 1em;
  }
}
#identityVerificationContainer {
  padding: 1em 0;
  margin: 1em auto;
  .title,
  .hr {
    font-weight: 500;
    width: 350px;
    max-width: 100%;
  }
  .hr {
    margin: -8px 0 16px;
  }
  input,
  select {
    width: 350px;
    max-width: 100%;
  }
  .form-group {
    margin-bottom: 1em;
  }
  .name-container {
    margin-bottom: 1.25em;
  }
  .form___submit_container {
    margin-top: 1.5em;
  }
}
.box-shadow-container {
-webkit-box-shadow: 7px 8px 7px -7px rgba(79,82,80,1);
-moz-box-shadow: 7px 8px 7px -7px rgba(79,82,80,1);
box-shadow: 7px 8px 7px -7px rgba(79,82,80,1);
}
a.btn-delete {
  padding: 0.2em 0.4em 0.2em 0.3em
}
div.input-group.date {
  margin-top: -2px;
}
div.org-container {
  legend {
    color: @legendColor;
  }
  &.sub-org-container {
    margin: 0.1em 0;
  }
  label.org-label{
    margin: 0.2em 0;
  }
  label.text-muted {
    border-bottom: solid 1px #e5e5e5;
  }
  label.org-label.text-muter {
    margin-bottom: 4px;
    margin-top:4px;
    font-size: @baseFontSize;
    color: #777474;
  }
  label.org-label.text-muter.data-display-only {
    margin-left: -0.3em;
    border-bottom: solid 1px #e5e5e5;
  }
  input[type="checkbox"] {
    position:relative;
    top:-1px;
    right:4px;
  }
}
div.parent-org-container {
  margin-bottom: 0.5em;
}
.indent {
  padding: 0 1.2em;
}
.registration-status-container {
  margin:0 0 0.8em 0;
}
.registration-label {
  display:inline-block;
  opacity: 0.7;
  margin-top: 1.25em;
  margin-bottom: 1.25em !important;
}
.timezone-container {
  padding-bottom: 0;
}
div.timezone-warning.text-warning {
  flex: 1 100%;
}
.gradient {
  background-image: -moz-linear-gradient(to top right, #FFF, #e1e2e1);
  background-image: -webkit-linear-gradient(to top right, #FFF, #e1e2e1);
  background-image: linear-gradient(to top right, #FFF, #e1e2e1);
}
.tnth-hide {
  display: none;
}
.capitalize {
  text-transform: capitalize;
}
.first-letter {
  display: block;
  &::first-letter {
    text-transform: capitalize;
  }
}

#consentListTable,
#profileAuditLogTable,
#userSessionListTable,
#consentHistoryTable {
    font-size: @mobileSmallSize;
    width: 100%;
    max-width: 100%;
}
#profileAuditLogTable {
  td {
    word-break: break-all;
    .second {
      display: block;
      width: 500px;
      max-width: 100%;
    }
  }
}
.opaque {
  opacity: 0 !important;
}
.visible {
  display: block !important;
  visibility: visible !important;
}
.invisible {
  display: none !important;
  visibility: hidden !important;
}
.help-block, .error-message {
  &.silent {
    display: none;
  }
}
/** Responsive sizes **/
@media (min-width: 423px) {
  .portal-header {
    line-height: 42px;
  }
  #termsText {
    padding: 0 1em;
  }
}
@media (min-width: 506px) {
  #consentListTable,
  #profileAuditLogTable,
  #userSessionListTable,
  #consentHistoryTable {
    font-size: @mediumText;
  }
}
@media (min-width: 507px) {
  .admin-table {
    div.pull-right {
      width: auto;
    }
    div.pull-right.search {
      width: auto;
      float: right !important;
    }
  }
}
@media (min-width: 580px) {
  #createProfileForm  {
    #emailGroup {
      margin-top: 0.25em;
    }
    #phone, #email, #profileStudyIDContainer input, #altPhone{
        width: 350px;
    }
  }
}
@media (min-width: 641px) {
  body {
    font-size: @baseFontSize;
  }
  .form-control,
  input,
  select,
  label,
  .form-group > label {
    font-size: @baseFontSize;
  }
  .form-group .help-block {
    font-size: @mediumText;
  }
  small {
    font-size: @baseMobileFontSize;
  }
  .fixed-table-pagination {
    font-size:@baseFontSize;
  }
  .tnth-headline {
    margin: 32px 0 24px;
    font-size: @headlineSize;
    letter-spacing: 1px;
  }
  .tnth-subhead {
    font-size: @subheadSize;
  }
  h1, h2 {
    font-size: @headlineSize;
  }
  h3, h4, h5 {
    font-size: 1.15em;
  }
  legend {
    font-size: 0.95em;
  }
  .fixed-table-container thead th,
  .fixed-table-container thead th .both {
    font-size: @baseFontSize*0.98;
  }

  .fixed-table-toolbar .dropdown-menu {
    label {
      font-size: @mediumText;
    }
    overflow-x: hidden;
    min-width: 250px;
  }
  .admin-table {
      th.id-field div.th-inner,
      th.organization-field div.th-inner {
        position: relative;
      }
  }
  .tnth-form .form-group > label {
    font-size: @formSize;
  }

  button,
  #createUserLink,
  .btn,
  .btn-tnth-primary,
  a.btn {
    font-size: @btnSize;
  }
  .sm-btn {
    font-size: 0.9em;
  }
  .modal-body,
  .modal-footer {
  font-size: @baseFontSize;
  }
  .modal-title {
    font-size: @modalTitleSize;
  }
  .modal-header {
    font-size: @modalHeaderSize;
  }
  .modal label {
    font-size: @modalLabelSize;
  }
  .modal button,
  .modal a.btn {
    font-size: @modalButtonSize;
  }

  #socialMediaRegistrationContainer .btn-social,
  #regForm input[type="submit"],
  #socialMediaLoginContainer .btn-social,
  #loginForm input[type="submit"],
  #regForm input[type=submit]
  {
    font-size: @formSize;
    height: 62px;
  }
  #loginForm input,
  #regForm input {
    font-size: @formSize;
  }
  #socialMediaRegistrationContainer .btn-social,
  #socialMediaLoginContainer .btn-social
  {
    padding-top: 0em;
  }

  .checkbox input[type=checkbox] {
    top: -1px;
  }

  #homeFooter {
    font-size: @footerSize;
    padding-top: 1em;
  }

  #wellContainer {
    .tnth-well {
      height: 100%;
    }
  }

  div.columns {
    top: 0;
  }
  div.search {
    top: 0;
  }

  #adminTableToolbar {
    div.indent {
      padding: 0;
    }
    div.orglist-selector {
      #fillOrgs {
        margin-left: 0.5em;
      }
      div.dropdown-menu {
        width: 400px;
        max-width: 400px;
        #userOrgs {
          margin: 0;
          padding: 0 1em;
        }
        div.org-container
        {
          * {
            font-size: @baseMobileFontSize;
          }
        }
      }
      #orglist-footer-container {
        padding: 0em 0.5em;
        label {
          font-size: @baseMobileFontSize;
        }
      }
    }
  }
  .download-wrapper,
  .download-break {
    display: block;
  }
  .portal-description .button-container a {
    width: 300px;
    padding: 0.8em;
  }
   .profile-item-container {
    padding: 2em 3em 2.5em 3em;
  }

  .profile-section {
      padding-top: 0.25em;
      padding-bottom: 0.25em;
  }

  #mainDiv.profile {
    .profile-item-container {
      .profile-item-title  {
        max-width: 100%;
      }
    }
    .copyright-container {
      width: 60%;
    }
    #manualEntryCompletionDateContainer {
      .flex {
        flex-wrap: nowrap
      }
    }
  }

  #profileForm {
    #firstname,
    #lastname {
      width: 300px;
    }
  }

  .timezone-container {
    padding-bottom: 2em;
  }

   #fillOrgs *,
  .noOrg-container * {
    font-size:@orgSize;
  }
  .create-account-container {
      padding: 3em;
      overflow: auto;
  }
  #createProfileForm {
    #bdGroup .flex {
      flex-wrap: nowrap;
    }
  }
  .work-instruction-title {
    & .pull-right {
      top: 4px
    }
  }
}

#fullSizeBox {
  > img {
    width: 100%;
    margin: auto;
  }
}

@media (min-width: 768px) {
  #fullSizeContainer {
    padding: 24px 0;
  }
  #wellContainer {
    padding: 24px 24px 80px;
  }
  #fullSizeLogo {
    height: 80px;
  }
  .tnth-well {
    padding: 24px;
  }
  .tnth-splash {
    .headline {
      font-weight: bold;
      margin-bottom: 1em;
    }
    .feature-btn-primary {
      font-weight: bold;
      padding: 0.7em 2.5em;
    }
  }
  .tnth-splash-bottom-link {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  #mainNav {
    height: 135px;
  }
  select:not([multiple]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding-right: 1.5em;
  }
  #notificationBanner {
    padding: 0.1em 0;
    &, span {
      font-size: 1em;
    }
  }
  #patientListOptions,
  #staffListOptions {
     flex-direction: row;
  }
  #patientList div.or,
  #staffList div.or {
    margin: 1em 1.3em 1.3em 1.6em;
  }
  #patientListOptions > span:last-of-type,
  #staffListOptions > span:last-of-type {
    margin-top: 0.7em;
  }
  #wellContainer{
    height:90%;
  }
  .tnth-headline {
    font-size: @headlineSize;
    letter-spacing: 1px;
  }
  .tnth-subhead {
    font-size: @subheadSize;
  }
  #fullSizeContainer {
    padding: 30px 0;
  }
  #wellContainer {
    padding: 30px 30px 90px;
  }
  #fullSizeLogo {
    height: 90px;
  }
  #fullSizeBox {
    background-position: center top;
  }
  .tnth-well {
    padding: 30px;
  }
  .btn-set {
    margin-bottom: 2em;
    .btn {
      margin-bottom: 1em;
    }
  }
  .tnth-splash .headline {
    font-size: @lgFontSize;
  }
  .pos-bottom-md {
    position: absolute;
    bottom:0;
    right:0;
    .btn {
      margin: 0 0 15px 15px;
    }
  }
  .custom-container {
    display: flex;
    .custom-container-item,
    .custom-container-item-right {
        width: 33%;
    }
    #profileEmailSelect,
    .email-selector {
      width: 100%;
    }
    #btnProfileSendEmail, .custom-btn,
    .btn-send-email {
      width: 100%;
      white-space: pre-wrap;
    }
  }
  .form-group {
    input[type=checkbox] {
      margin-top: 5px;
    }
    input[type=radio] {
      margin-top: 3px;
    }
  }

  #progressWrapper {
      width: 80%;
      .progressbar {
        position: relative;
        left: -3%;
        li {
          font-size: 12px;
        }
      }
  }
  /** Unless explicitly wrapped in reduce-font-sizes class **/
  .reduce-font-sizes {
    body & {
      font-size: @mediumText;
    }
    .tnth-headline {
      font-size: @headlineSize;
    }
    .tnth-subhead {
      font-size: @subheadSize;
    }
    .form-group input[type=checkbox], .form-group  input[type=radio] {
      margin-top: 4px;
    }
  }

  #topTerms {
    .terms-checkbox-container {
      margin-left: 1em;
      .terms-tick-box-text {
        margin-left: 0;
        width: 96.5%;
      }
      .edit-view {
        .terms-tick-box-text {
          width: 94%;
        }
      }
    }
  }

  #aboutForm {
    max-width: 80%;
    .iq-container {
      .tnth-headline:first-of-type {
        font-size: @subheadSize;
      }
    }
  }

  #aboutForm.full-size {
    max-width: 100%;
  }

  #profileConsentHistory {
    .modal-dialog {
      width: 80%;
      max-width: 100%;
    }
  }
  #mainDiv.profile {
    .copyright-container {
      width: 80%;
    }
  }
  #manualEntryModal {
    .modal-dialog {
      width: 650px;
    }
  }
}
@media (min-width: 1200px) {
  #fullSizeContainer {
    padding: 60px 0 60px;
  }
  #wellContainer {
    padding: 50px 50px 100px;
  }
  #fullSizeLogo {
    height: 100px;
  }
  .tnth-well {
    padding: 60px;
  }
  .tnth-splash .headline {
    margin: -3.2em 0 1.5em;
  }
  .profile-img {
    display: inline-block;
    margin-top: 10px;
    margin-left: 6px;
    img {
      width: 60px;
      border-radius: 45px;
    }
  }
  .btn-set {
    margin-bottom: 1em;
    .btn {
      margin-bottom: 0.5em;
    }
  }

  #aboutForm div.heading::before {
     border-radius: 100%;
     width: 52px;
     height: 52px;
     font-size: @subheadSize;
     line-height: 1;
     content: "\EA03";
     display: inline-block;
     font-family: "symbols";
     font-style: normal;
     font-weight: normal;
     line-height: 1;
     margin: 0 auto;
     -webkit-font-smoothing: antialiased;
     color: #ccc;
     position: absolute;
     top: 2.8em;
     left: 0;
     right: 0;
     margin: 0 auto 1em auto;
     cursor: pointer;
   }

  #mainDiv.profile .footer-container.flex{
    justify-content: left;
  }

  #profileForm {
     .patient-detail-container .flex-item {
        flex: 1;
      }
    .communications-container .flex-item {
        flex: 1 30%;
      }
  }
  #profileProcedureContainer {
     .button-container {
      margin-top: 2em;
    }
  }
  #patientList {
    .admin-table {
      div.pull-right,
      div.pull-right.search {
        top: 0.75em;
      }
    }
  }
}
#progressWrapper.initial-queries {
  display: none !important;
}

@media(min-width: 1301px) {
  #fullSizeBox {
    height: 100%;
  }
}

@media (min-width: 1400px) {
  html, body {
    width: 100%;
    height: 100%;
  }
  #fullSizeBox {
    margin: 1em auto;
    max-width: 1400px;
  }
  #homeFooter {
    max-width: 1400px;
    margin: 0 auto;
  }
  .btn-set {
    margin-bottom: 1em;
    .btn {
      margin-bottom: 0.5em;
    }
  }
}
/*** https://github.com/lipis/bootstrap-social ***/
.btn-social{position:relative;padding-left:44px;text-align:left;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.btn-social>:first-child{position:absolute;left:0;top:0;bottom:0;width:32px;line-height:39px;font-size:1.6em;text-align:center;border-right:1px solid rgba(0,0,0,0.2)}
.btn-social.btn-lg{padding-left:61px}.btn-social.btn-lg>:first-child{line-height:45px;width:45px;font-size:1.8em}
.btn-social.btn-sm{padding-left:38px}.btn-social.btn-sm>:first-child{line-height:28px;width:28px;font-size:1.4em}
.btn-social.btn-xs{padding-left:30px}.btn-social.btn-xs>:first-child{line-height:20px;width:20px;font-size:1.2em}
.btn-social-icon{position:relative;padding-left:44px;text-align:left;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;height:34px;width:34px;padding:0}.btn-social-icon>:first-child{position:absolute;left:0;top:0;bottom:0;width:32px;line-height:34px;font-size:1.6em;text-align:center;border-right:1px solid rgba(0,0,0,0.2)}
.btn-social-icon.btn-lg{padding-left:61px}.btn-social-icon.btn-lg>:first-child{line-height:45px;width:45px;font-size:1.8em}
.btn-social-icon.btn-sm{padding-left:38px}.btn-social-icon.btn-sm>:first-child{line-height:28px;width:28px;font-size:1.4em}
.btn-social-icon.btn-xs{padding-left:30px}.btn-social-icon.btn-xs>:first-child{line-height:20px;width:20px;font-size:1.2em}
.btn-social-icon>:first-child{border:none;text-align:center;width:100% !important}
.btn-social-icon.btn-lg{height:45px;width:45px;padding-left:0;padding-right:0}
.btn-social-icon.btn-sm{height:30px;width:30px;padding-left:0;padding-right:0}
.btn-social-icon.btn-xs{height:22px;width:22px;padding-left:0;padding-right:0}
.btn-facebook{color:#fff;background-color:#3b5998;border-color:rgba(0,0,0,0.2)}.btn-facebook:hover,.btn-facebook:focus,.btn-facebook:active,.btn-facebook.active,.open>.dropdown-toggle.btn-facebook{color:#fff;background-color:#2d4373;border-color:rgba(0,0,0,0.2)}
.btn-facebook:active,.btn-facebook.active,.open>.dropdown-toggle.btn-facebook{background-image:none}
.btn-facebook.disabled,.btn-facebook[disabled],fieldset[disabled] .btn-facebook,.btn-facebook.disabled:hover,.btn-facebook[disabled]:hover,fieldset[disabled] .btn-facebook:hover,.btn-facebook.disabled:focus,.btn-facebook[disabled]:focus,fieldset[disabled] .btn-facebook:focus,.btn-facebook.disabled:active,.btn-facebook[disabled]:active,fieldset[disabled] .btn-facebook:active,.btn-facebook.disabled.active,.btn-facebook[disabled].active,fieldset[disabled] .btn-facebook.active{background-color:#3b5998;border-color:rgba(0,0,0,0.2)}
.btn-facebook .badge{color:#3b5998;background-color:#fff}
.btn-google{color:#fff;background-color:#dd4b39;border-color:rgba(0,0,0,0.2)}.btn-google:hover,.btn-google:focus,.btn-google:active,.btn-google.active,.open>.dropdown-toggle.btn-google{color:#fff;background-color:#c23321;border-color:rgba(0,0,0,0.2)}
.btn-google:active,.btn-google.active,.open>.dropdown-toggle.btn-google{background-image:none}
.btn-google.disabled,.btn-google[disabled],fieldset[disabled] .btn-google,.btn-google.disabled:hover,.btn-google[disabled]:hover,fieldset[disabled] .btn-google:hover,.btn-google.disabled:focus,.btn-google[disabled]:focus,fieldset[disabled] .btn-google:focus,.btn-google.disabled:active,.btn-google[disabled]:active,fieldset[disabled] .btn-google:active,.btn-google.disabled.active,.btn-google[disabled].active,fieldset[disabled] .btn-google.active{background-color:#dd4b39;border-color:rgba(0,0,0,0.2)}
.btn-google .badge{color:#dd4b39;background-color:#fff}

#createAcctTxt {
  margin: 2em 0 0.5em;
}
.btn-social-container {
  width: 450px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1.5em;
}
#socialMediaRegistrationContainer .btn-social > :first-child,
#socialMediaLoginContainer .btn-social > :first-child {
  width: 40px;
  top: 0.4em;
}

#socialMediaRegistrationContainer .divider,
#socialMediaLoginContainer .divider
 {
  height: 0.5em;
}
div.or {
    display: none;
    background: @orBackgroundColor;
    border-radius: 12px;
    color: #fff;
    font-size: @xxsmallText;
    font-weight: bold;
    height: 26px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    width: 25px;
    margin: 1em auto;
    position: relative;
    z-index: 1;
}
.or-divider {
    display: none;
    height: 1px;
    background: #ebebeb;
    position: relative;
    margin: -1.3em auto 20px auto;
    width: 100%;
}
.password-popover { min-width: 200px; max-width: 250px; right: 50px !important;}
.password-popover .popover-content { padding: 1.2em 1em 1.5em 1em; width: 100%; margin: 0 auto;}
#pwdHintList {padding: 0; margin:2px; list-style-type: none}
.password-popover li { margin-left: 1em;}
.password-popover li.success-text::before { content: "\2713"; display: inline-block; margin-right: 4px; }
.success-text { color: green; font-weight: bold;}
.default-text { color: #31708f; font-weight: normal;  }
.fail-text {color: #a94442; font-weight: bold;}
.password-popover li.fail-text::before { content: "\2715"; display: inline-block; margin-right: 4px; }

#socialMediaRegistrationContainer .btn-social,
#socialMediaLoginContainer .btn-social
{
    padding-top: 1.7em;
}

#developmentToolsContainer {
  margin-left: 32px;
  margin-right: 32px;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
  .loader,
  .error {
    margin-top: 16px;
  }
}
.wait-indicator-wrapper {
    position: fixed;
    background: hsla(0, 7%, 12%, 0.35);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 888;
}
// modified from https://codepen.io/aitchiss/pen/rNxqEMP
.wait-indicator-container {
  position: relative;
  margin: auto;
  display: flex;
  margin-top: calc(~"25% - 24px");
  margin-bottom: 8%;
  width: 120px;
  height: 280px;
  .loading-text-container {
    position: relative;
    width: 120px;
    //top: 16px;
    margin: auto;
    color: #FFF;
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: .1rem;
  }
  .frame {
    position: absolute;
    width: 100px;
    height: 100px;
    border-top: 10px solid @hourGlassFrameColor;
    border-bottom: 10px solid @hourGlassColor;
    border-radius: 4px;
    animation: rotateFrame 460s infinite;
  }
  .top {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 68px;
    height: 40px;
    clip-path: polygon(45% 100%, 55% 100%, 100% 0, 0 0);
  }
  /* Sand - top */
  .top::before {
    content: '';
    position: absolute;
    width: 68px;
    height: 44px;
    bottom: 0;
    background: @hourGlassSandColor;
    animation: 520s lowerTopSand infinite;
  }
  
  .top::after {
    content: '';
    position: absolute;
    top: 0px;
    left: -15px;
    width: 190px;
    height: 190px;
    transform: rotate(-90deg);
    background: conic-gradient(
      from 0deg, 
      white 0deg, 
      transparent 90deg,
      white 180deg
    );
  }
  .bottom {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 40px;
    width: 68px;
    height: 40px;
    clip-path: polygon(45% 0, 55% 0, 100% 100%, 0 100%);
  }
  
  /* Bottom sand */
  .bottom::before {
    content: '';
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: 160px;
    height: 80px;
    bottom: 0;
    background: @hourGlassSandColor;
    animation: 520s raiseBottomSand infinite;
  }
  
  .blob {
    position: absolute;
    transform: translateX(-50%);
    top: 10px;
    left: 50%;
    content: '';
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: @hourGlassSandColor;
    animation: raiseMound 460s infinite;
  }
  /* Drip through to bottom */
  .drip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid @hourGlassSandColor;
    animation: fadeDrip 2s infinite;
  }

  .drip::before {
    content: '';
    position: absolute;
    left: -1px;
    width: 3px;
    height: 200px;
    background: repeating-linear-gradient(to bottom,
      @hourGlassSandColor,
      @hourGlassSandColor 5px,
      transparent 5px,
      transparent 10px
    );
    animation: drip 2s infinite;
  }
  
  .glass {
    position: absolute;
    top: -90px;
    left: -15px;
    width: 190px;
    height: 190px;
    transform: rotate(-270deg);
    background: conic-gradient(
      from 0deg, 
      white 0deg, 
      transparent 90deg,
      white 180deg
    );
  }  
  
}

@keyframes rotateFrame {
  0% {
    transform: none;
  }
  
  90% {
    transform: none;
  }
  
  100% {
    transform: rotate(180deg);
  }
}

@keyframes lowerTopSand {
  0% {
    transform: none;
  }
  
  100% {
    transform: translateY(80px);
  }
}


@keyframes raiseMound {
  0% {
    transform: translate(-50%, 80px);
    width: 180px;
  }
  
  100% {
    transform: translateX(-50%);
    width: 50px;
  }
}

@keyframes raiseBottomSand {
  0% {
    transform: translate(-50%, 80px);
    boder-radius: 0;
  }
  
  100% {
    transform: translateX(-50%);
    border-radius: 50% 50% 0 0;
  }
}

@keyframes fadeDrip {
  0% {
    opacity: 1;
  }
  
  70% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
}

@keyframes drip {
  0% {
    transform: translateY(-150px);
    opacity: 1;
  }
  
  99% {
    opacity: 1;
  }
  
  100% {
    transform: translateY(30px);
  }
}

@media screen {
  #printSection {
      display: none;
  }
}
@media print {
  #printSection, #printSection * {
      visibility:visible;
  }
  #printSection {
      position:absolute;
      left:0;
      top:0;
  }
  #websiteDeclarationFormModal {
    .modal-content {
      border: 0;
    }
  }
  #userSessionReportDetailHeader {
    background-image: none !important;
    border: 0;
    height: auto;
  }
  #userSessionReportMainContainer [data-toggle] {
    display: none;
  }
  #tnthNavWrapper, #tnthTopLinks, #tnthNavXs, #tnthUserBtn, #homeFooter {
    display: none !important;
  }
  #mainNav {
    height: 0;
  }
  .watermark {
    display: none;
  }
}
